/**======================================================================
=========================================================================
Template Name: Able pro Bootstrap Admin Template
Author: Phoenixcoded
Support: support@phoenixcoded.net
File: style.css
=========================================================================
=================================================================================== */
/* cyrillic-ext */
@import url(plugins/animate.min.css);

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(../fonts/opensans/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(../fonts/opensans/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(../fonts/opensans/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(../fonts/opensans/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(../fonts/opensans/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(../fonts/opensans/mem8YaGs126MiZpBA-UFW50bbck.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVZ0b.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(../fonts/opensans/mem5YaGs126MiZpBA-UNirkOX-hpOqc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(../fonts/opensans/mem5YaGs126MiZpBA-UNirkOVuhpOqc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(../fonts/opensans/mem5YaGs126MiZpBA-UNirkOXuhpOqc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(../fonts/opensans/mem5YaGs126MiZpBA-UNirkOUehpOqc.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(../fonts/opensans/mem5YaGs126MiZpBA-UNirkOXehpOqc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(../fonts/opensans/mem5YaGs126MiZpBA-UNirkOXOhpOqc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UNirkOUuhp.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
.paybackground {
    position: fixed; /* Keep fixed position */
    top: 0;
    left: 0;
    width: 100%;
    padding: 50px;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: auto;
    transform: translateY(-20px);
    animation: popIn 0.3s ease-out forwards; 
  }
  
  @keyframes popIn {
    0% {
      transform: translateY(-20px); 
    }
    
    100% {
      transform: translateY(0);
    }
  }
  
/* --------------------------Loading---------------------   */
.outerspinner {
  position: fixed; /* Change from fixed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  visibility: hidden;
}
.outerspinner.active {
  visibility: visible;
}
.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 40%, #000000 41%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1) 0)
      center/4.5px 100%,
    linear-gradient(90deg, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.75) 0)
      center/100% 4.5px;
  background-repeat: no-repeat;
  animation: spinner-d3o0rx 1.2s infinite steps(12);
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.spinner::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes spinner-d3o0rx {
  100% {
    transform: rotate(1turn);
  }
}
/* --------------------------Loading---------------------   */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #4680ff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ff5252;
  --orange: #fd7e14;
  --yellow: #ffba57;
  --green: #9ccc65;
  --teal: #20c997;
  --cyan: #00acc1;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #4680ff;
  --secondary: #6c757d;
  --success: #9ccc65;
  --info: #00acc1;
  --warning: #ffba57;
  --danger: #ff5252;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
body,
#root,
.App {
  height: 100%; /* Ensure full height for each parent */
  margin: 0; /* Remove default margin for consistency */
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: left;
  background-color: #ecf0f5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #4680ff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #4680ff;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2.25rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.25rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  width: 200px;
  height: 150px;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ecf0f5;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #373a3c;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e3eaef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3eaef;
}

.table tbody + tbody {
  border-top: 2px solid #e3eaef;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e3eaef;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e3eaef;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #373a3c;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cbdbff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9fbdff;
}

.table-hover .table-primary:hover {
  background-color: #b2c9ff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b2c9ff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e3f1d4;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #cce4af;
}

.table-hover .table-success:hover {
  background-color: #d6ebc1;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d6ebc1;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e8ee;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ad4df;
}

.table-hover .table-info:hover {
  background-color: #a3e1e9;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a3e1e9;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffecd0;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdba8;
}

.table-hover .table-warning:hover {
  background-color: #ffe2b7;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe2b7;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffcfcf;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ffa5a5;
}

.table-hover .table-danger:hover {
  background-color: #ffb6b6;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ffb6b6;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e3eaef;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 1.1875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c6d8ff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(70, 128, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.7rem + 1px);
  padding-bottom: calc(0.7rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.625rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #373a3c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1.4rem + 2px);
  padding: 0.7rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #868e96;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #9ccc65;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(156, 204, 101, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #9ccc65;
  padding-right: calc(1.5em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%239ccc65' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3125rem) center;
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #9ccc65;
  box-shadow: 0 0 0 0rem rgba(156, 204, 101, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right
    calc(0.375em + 0.3125rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #9ccc65;
  padding-right: calc((1em + 1.25rem) * 3 / 4 + 2.1875rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 1.1875rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%239ccc65' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 2.1875rem / calc(0.75em + 0.625rem)
      calc(0.75em + 0.625rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #9ccc65;
  box-shadow: 0 0 0 0rem rgba(156, 204, 101, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #9ccc65;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #9ccc65;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #9ccc65;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #b5d98b;
  background-color: #b5d98b;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(156, 204, 101, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #9ccc65;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #9ccc65;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #9ccc65;
  box-shadow: 0 0 0 0rem rgba(156, 204, 101, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff5252;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(255, 82, 82, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #ff5252;
  padding-right: calc(1.5em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff5252' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff5252' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3125rem) center;
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #ff5252;
  box-shadow: 0 0 0 0rem rgba(255, 82, 82, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right
    calc(0.375em + 0.3125rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ff5252;
  padding-right: calc((1em + 1.25rem) * 3 / 4 + 2.1875rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 1.1875rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff5252' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff5252' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      #fff no-repeat center right 2.1875rem / calc(0.75em + 0.625rem)
      calc(0.75em + 0.625rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #ff5252;
  box-shadow: 0 0 0 0rem rgba(255, 82, 82, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ff5252;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff5252;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ff5252;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff8585;
  background-color: #ff8585;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(255, 82, 82, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #ff5252;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff5252;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ff5252;
  box-shadow: 0 0 0 0rem rgba(255, 82, 82, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #373a3c;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.1875rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #373a3c;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(70, 128, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #4680ff;
  border-color: #4680ff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2066ff;
  border-color: #135dff;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #2066ff;
  border-color: #135dff;
  box-shadow: 0 0 0 0rem rgba(98, 147, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #4680ff;
  border-color: #4680ff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #135dff;
  border-color: #0654ff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(98, 147, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #9ccc65;
  border-color: #9ccc65;
}

.btn-success:hover {
  color: #fff;
  background-color: #8ac248;
  border-color: #83bf3f;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #8ac248;
  border-color: #83bf3f;
  box-shadow: 0 0 0 0rem rgba(171, 212, 124, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #9ccc65;
  border-color: #9ccc65;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #83bf3f;
  border-color: #7db53c;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(171, 212, 124, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-info:hover {
  color: #fff;
  background-color: #008a9b;
  border-color: #007f8e;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #008a9b;
  border-color: #007f8e;
  box-shadow: 0 0 0 0rem rgba(38, 184, 202, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #007f8e;
  border-color: #007381;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(38, 184, 202, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #ffba57;
  border-color: #ffba57;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ffaa31;
  border-color: #ffa524;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #ffaa31;
  border-color: #ffa524;
  box-shadow: 0 0 0 0rem rgba(255, 196, 112, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #ffba57;
  border-color: #ffba57;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffa524;
  border-color: #ffa017;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(255, 196, 112, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ff5252;
  border-color: #ff5252;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ff2c2c;
  border-color: #ff1f1f;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #ff2c2c;
  border-color: #ff1f1f;
  box-shadow: 0 0 0 0rem rgba(255, 108, 108, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ff5252;
  border-color: #ff5252;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff1f1f;
  border-color: #ff1212;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(255, 108, 108, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #4680ff;
  border-color: #4680ff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #4680ff;
  border-color: #4680ff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0rem rgba(70, 128, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #4680ff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4680ff;
  border-color: #4680ff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(70, 128, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #9ccc65;
  border-color: #9ccc65;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #9ccc65;
  border-color: #9ccc65;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0rem rgba(156, 204, 101, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #9ccc65;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #9ccc65;
  border-color: #9ccc65;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(156, 204, 101, 0.5);
}

.btn-outline-info {
  color: #00acc1;
  border-color: #00acc1;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0rem rgba(0, 172, 193, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00acc1;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(0, 172, 193, 0.5);
}

.btn-outline-warning {
  color: #ffba57;
  border-color: #ffba57;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffba57;
  border-color: #ffba57;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0rem rgba(255, 186, 87, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffba57;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffba57;
  border-color: #ffba57;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(255, 186, 87, 0.5);
}

.btn-outline-danger {
  color: #ff5252;
  border-color: #ff5252;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff5252;
  border-color: #ff5252;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0rem rgba(255, 82, 82, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ff5252;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff5252;
  border-color: #ff5252;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(255, 82, 82, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #4680ff;
  text-decoration: none;
}

.btn-link:hover {
  color: #4680ff;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.7rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #373a3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #4680ff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.89063rem;
  padding-left: 0.89063rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.1875rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.4rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.7rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.1875rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4680ff;
  background-color: #4680ff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(70, 128, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #c6d8ff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #f9fbff;
  border-color: #f9fbff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #4680ff;
  background-color: #4680ff;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(70, 128, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(70, 128, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(70, 128, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(70, 128, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 2.1875rem 0.625rem 1.1875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 1.1875rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #c6d8ff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(70, 128, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1.1875rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1.4rem + 2px);
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 2rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #c6d8ff;
  box-shadow: 0 0 0 0rem rgba(70, 128, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 1.1875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.25rem);
  padding: 0.625rem 1.1875rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ecf0f5, 0 0 0 0rem rgba(70, 128, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ecf0f5, 0 0 0 0rem rgba(70, 128, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ecf0f5, 0 0 0 0rem rgba(70, 128, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #4680ff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #f9fbff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4680ff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #f9fbff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0rem;
  margin-left: 0rem;
  background-color: #4680ff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #f9fbff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4680ff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 1.25rem;
}

.card-subtitle {
  margin-top: -0.625rem;
  margin-bottom: 0;
}
.flexWithPadding {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.gap-10 {
  display: flex;
  gap: 10px;
}
.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 0px) calc(0.25rem - 0px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1.25rem 1.25rem;
  background-color: rgba(0, 0, 0, 0);
  border-top: 0px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 0px) calc(0.25rem - 0px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1.25rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 0px);
  border-top-right-radius: calc(0.25rem - 0px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 0px);
  border-bottom-left-radius: calc(0.25rem - 0px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.25rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #ecf0f5;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #4680ff;
  background-color: #fff;
  border: 0 solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #4680ff;
  text-decoration: none;
  background-color: #ecf0f5;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(70, 128, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4680ff;
  border-color: #4680ff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #4680ff;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #135dff;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(70, 128, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #9ccc65;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #83bf3f;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(156, 204, 101, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #00acc1;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #007f8e;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 172, 193, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #ffba57;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #fff;
  background-color: #ffa524;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 186, 87, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ff5252;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #ff1f1f;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 82, 82, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #244385;
  background-color: #dae6ff;
  border-color: #cbdbff;
}

.alert-primary hr {
  border-top-color: #b2c9ff;
}

.alert-primary .alert-link {
  color: #192f5d;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #516a35;
  background-color: #ebf5e0;
  border-color: #e3f1d4;
}

.alert-success hr {
  border-top-color: #d6ebc1;
}

.alert-success .alert-link {
  color: #374824;
}

.alert-info {
  color: #005964;
  background-color: #cceef3;
  border-color: #b8e8ee;
}

.alert-info hr {
  border-top-color: #a3e1e9;
}

.alert-info .alert-link {
  color: #002c31;
}

.alert-warning {
  color: #85612d;
  background-color: #fff1dd;
  border-color: #ffecd0;
}

.alert-warning hr {
  border-top-color: #ffe2b7;
}

.alert-warning .alert-link {
  color: #5f4520;
}

.alert-danger {
  color: #852b2b;
  background-color: #ffdcdc;
  border-color: #ffcfcf;
}

.alert-danger hr {
  border-top-color: #ffb6b6;
}

.alert-danger .alert-link {
  color: #5e1f1f;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 0.6rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.6rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 0.6rem;
  overflow: hidden;
  font-size: 0.5rem;
  background-color: #ecf0f5;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4680ff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.6rem 0.6rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #373a3c;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4680ff;
  border-color: #4680ff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #244385;
  background-color: #cbdbff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #244385;
  background-color: #b2c9ff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #244385;
  border-color: #244385;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #516a35;
  background-color: #e3f1d4;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #516a35;
  background-color: #d6ebc1;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #516a35;
  border-color: #516a35;
}

.list-group-item-info {
  color: #005964;
  background-color: #b8e8ee;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #005964;
  background-color: #a3e1e9;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #005964;
  border-color: #005964;
}

.list-group-item-warning {
  color: #85612d;
  background-color: #ffecd0;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #85612d;
  background-color: #ffe2b7;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #85612d;
  border-color: #85612d;
}

.list-group-item-danger {
  color: #852b2b;
  background-color: #ffcfcf;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #852b2b;
  background-color: #ffb6b6;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #852b2b;
  border-color: #852b2b;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: scale(0.7);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: 1px solid #e3eaef;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 20px 20px;
  margin: -20px -20px -20px auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 20px 20px;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 20px-0.25rem;
  border-top: 1px solid #e3eaef;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.25rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.25rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.25rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #373a3c;
}

.carousel {
  position: relative;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: visible;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #4680ff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #135dff !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #9ccc65 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #83bf3f !important;
}

.bg-info {
  background-color: #00acc1 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #007f8e !important;
}

.bg-warning {
  background-color: #ffba57 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffa524 !important;
}

.bg-danger {
  background-color: #ff5252 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff1f1f !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e3eaef !important;
}

.border-top {
  border-top: 1px solid #e3eaef !important;
}

.border-right {
  border-right: 1px solid #e3eaef !important;
}

.border-bottom {
  border-bottom: 1px solid #e3eaef !important;
}

.border-left {
  border-left: 1px solid #e3eaef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #4680ff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #9ccc65 !important;
}

.border-info {
  border-color: #00acc1 !important;
}

.border-warning {
  border-color: #ffba57 !important;
}

.border-danger {
  border-color: #ff5252 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #4680ff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #004ef9 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #9ccc65 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #76ac39 !important;
}

.text-info {
  color: #00acc1 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #006875 !important;
}

.text-warning {
  color: #ffba57 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ff9b0b !important;
}

.text-danger {
  color: #ff5252 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ff0606 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #373a3c !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e3eaef;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #e3eaef;
  }
}

@font-face {
  font-family: "pct";
  src: url("../fonts/pct.eot");
  src: url("../fonts/pct.eot?#iefix") format("embedded-opentype"),
    url("../fonts/pct.woff") format("woff"),
    url("../fonts/pct.ttf") format("truetype"),
    url("../fonts/pct.svg#pct") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "pct" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="pct-"]:before,
[class*=" pct-"]:before {
  font-family: "pct" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pct-arrow1:before {
  content: "\61";
}

.pct-arrow2:before {
  content: "\62";
}

.pct-arrow3:before {
  content: "\63";
}

.pct-arrow4:before {
  content: "\64";
}

.pct-chat1:before {
  content: "\65";
}

.pct-chat2:before {
  content: "\66";
}

.pct-chat3:before {
  content: "\67";
}

.pct-chat4:before {
  content: "\68";
}

.pct-loader1:before {
  content: "\69";
}

.pct-arrow-sharp1:before {
  content: "\6a";
}

.pct-arrow-sharp2:before {
  content: "\6b";
}

.pct-arrow-sharp3:before {
  content: "\6c";
}

.pct-arrow-sharp4:before {
  content: "\6d";
}

@font-face {
  font-family: "feather";
  src: url("../fonts/feather.eot?t=1501841394106");
  /* IE9*/
  src: url("../fonts/feather.eot?t=1501841394106#iefix")
      format("embedded-opentype"),
    url("../fonts/feather.woff?t=1501841394106") format("woff"),
    url("../fonts/feather.ttf?t=1501841394106") format("truetype"),
    url("../fonts/feather.svg?t=1501841394106#feather") format("svg");
  /* iOS 4.1- */
}

.feather {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert-octagon:before {
  content: "\e81b";
}

.icon-alert-circle:before {
  content: "\e81c";
}

.icon-activity:before {
  content: "\e81d";
}

.icon-alert-triangle:before {
  content: "\e81e";
}

.icon-align-center:before {
  content: "\e81f";
}

.icon-airplay:before {
  content: "\e820";
}

.icon-align-justify:before {
  content: "\e821";
}

.icon-align-left:before {
  content: "\e822";
}

.icon-align-right:before {
  content: "\e823";
}

.icon-arrow-down-left:before {
  content: "\e824";
}

.icon-arrow-down-right:before {
  content: "\e825";
}

.icon-anchor:before {
  content: "\e826";
}

.icon-aperture:before {
  content: "\e827";
}

.icon-arrow-left:before {
  content: "\e828";
}

.icon-arrow-right:before {
  content: "\e829";
}

.icon-arrow-down:before {
  content: "\e82a";
}

.icon-arrow-up-left:before {
  content: "\e82b";
}

.icon-arrow-up-right:before {
  content: "\e82c";
}

.icon-arrow-up:before {
  content: "\e82d";
}

.icon-award:before {
  content: "\e82e";
}

.icon-bar-chart:before {
  content: "\e82f";
}

.icon-at-sign:before {
  content: "\e830";
}

.icon-bar-chart-2:before {
  content: "\e831";
}

.icon-battery-charging:before {
  content: "\e832";
}

.icon-bell-off:before {
  content: "\e833";
}

.icon-battery:before {
  content: "\e834";
}

.icon-bluetooth:before {
  content: "\e835";
}

.icon-bell:before {
  content: "\e836";
}

.icon-book:before {
  content: "\e837";
}

.icon-briefcase:before {
  content: "\e838";
}

.icon-camera-off:before {
  content: "\e839";
}

.icon-calendar:before {
  content: "\e83a";
}

.icon-bookmark:before {
  content: "\e83b";
}

.icon-box:before {
  content: "\e83c";
}

.icon-camera:before {
  content: "\e83d";
}

.icon-check-circle:before {
  content: "\e83e";
}

.icon-check:before {
  content: "\e83f";
}

.icon-check-square:before {
  content: "\e840";
}

.icon-cast:before {
  content: "\e841";
}

.icon-chevron-down:before {
  content: "\e842";
}

.icon-chevron-left:before {
  content: "\e843";
}

.icon-chevron-right:before {
  content: "\e844";
}

.icon-chevron-up:before {
  content: "\e845";
}

.icon-chevrons-down:before {
  content: "\e846";
}

.icon-chevrons-right:before {
  content: "\e847";
}

.icon-chevrons-up:before {
  content: "\e848";
}

.icon-chevrons-left:before {
  content: "\e849";
}

.icon-circle:before {
  content: "\e84a";
}

.icon-clipboard:before {
  content: "\e84b";
}

.icon-chrome:before {
  content: "\e84c";
}

.icon-clock:before {
  content: "\e84d";
}

.icon-cloud-lightning:before {
  content: "\e84e";
}

.icon-cloud-drizzle:before {
  content: "\e84f";
}

.icon-cloud-rain:before {
  content: "\e850";
}

.icon-cloud-off:before {
  content: "\e851";
}

.icon-codepen:before {
  content: "\e852";
}

.icon-cloud-snow:before {
  content: "\e853";
}

.icon-compass:before {
  content: "\e854";
}

.icon-copy:before {
  content: "\e855";
}

.icon-corner-down-right:before {
  content: "\e856";
}

.icon-corner-down-left:before {
  content: "\e857";
}

.icon-corner-left-down:before {
  content: "\e858";
}

.icon-corner-left-up:before {
  content: "\e859";
}

.icon-corner-up-left:before {
  content: "\e85a";
}

.icon-corner-up-right:before {
  content: "\e85b";
}

.icon-corner-right-down:before {
  content: "\e85c";
}

.icon-corner-right-up:before {
  content: "\e85d";
}

.icon-cpu:before {
  content: "\e85e";
}

.icon-credit-card:before {
  content: "\e85f";
}

.icon-crosshair:before {
  content: "\e860";
}

.icon-disc:before {
  content: "\e861";
}

.icon-delete:before {
  content: "\e862";
}

.icon-download-cloud:before {
  content: "\e863";
}

.icon-download:before {
  content: "\e864";
}

.icon-droplet:before {
  content: "\e865";
}

.icon-edit-2:before {
  content: "\e866";
}

.icon-edit:before {
  content: "\e867";
}

.icon-edit-1:before {
  content: "\e868";
}

.icon-external-link:before {
  content: "\e869";
}

.icon-eye:before {
  content: "\e86a";
}

.icon-feather:before {
  content: "\e86b";
}

.icon-facebook:before {
  content: "\e86c";
}

.icon-file-minus:before {
  content: "\e86d";
}

.icon-eye-off:before {
  content: "\e86e";
}

.icon-fast-forward:before {
  content: "\e86f";
}

.icon-file-text:before {
  content: "\e870";
}

.icon-film:before {
  content: "\e871";
}

.icon-file:before {
  content: "\e872";
}

.icon-file-plus:before {
  content: "\e873";
}

.icon-folder:before {
  content: "\e874";
}

.icon-filter:before {
  content: "\e875";
}

.icon-flag:before {
  content: "\e876";
}

.icon-globe:before {
  content: "\e877";
}

.icon-grid:before {
  content: "\e878";
}

.icon-heart:before {
  content: "\e879";
}

.icon-home:before {
  content: "\e87a";
}

.icon-github:before {
  content: "\e87b";
}

.icon-image:before {
  content: "\e87c";
}

.icon-inbox:before {
  content: "\e87d";
}

.icon-layers:before {
  content: "\e87e";
}

.icon-info:before {
  content: "\e87f";
}

.icon-instagram:before {
  content: "\e880";
}

.icon-layout:before {
  content: "\e881";
}

.icon-link-2:before {
  content: "\e882";
}

.icon-life-buoy:before {
  content: "\e883";
}

.icon-link:before {
  content: "\e884";
}

.icon-log-in:before {
  content: "\e885";
}

.icon-list:before {
  content: "\e886";
}

.icon-lock:before {
  content: "\e887";
}

.icon-log-out:before {
  content: "\e888";
}

.icon-loader:before {
  content: "\e889";
}

.icon-mail:before {
  content: "\e88a";
}

.icon-maximize-2:before {
  content: "\e88b";
}

.icon-map:before {
  content: "\e88c";
}

.icon-map-pin:before {
  content: "\e88e";
}

.icon-menu:before {
  content: "\e88f";
}

.icon-message-circle:before {
  content: "\e890";
}

.icon-message-square:before {
  content: "\e891";
}

.icon-minimize-2:before {
  content: "\e892";
}

.icon-mic-off:before {
  content: "\e893";
}

.icon-minus-circle:before {
  content: "\e894";
}

.icon-mic:before {
  content: "\e895";
}

.icon-minus-square:before {
  content: "\e896";
}

.icon-minus:before {
  content: "\e897";
}

.icon-moon:before {
  content: "\e898";
}

.icon-monitor:before {
  content: "\e899";
}

.icon-more-vertical:before {
  content: "\e89a";
}

.icon-more-horizontal:before {
  content: "\e89b";
}

.icon-move:before {
  content: "\e89c";
}

.icon-music:before {
  content: "\e89d";
}

.icon-navigation-2:before {
  content: "\e89e";
}

.icon-navigation:before {
  content: "\e89f";
}

.icon-octagon:before {
  content: "\e8a0";
}

.icon-package:before {
  content: "\e8a1";
}

.icon-pause-circle:before {
  content: "\e8a2";
}

.icon-pause:before {
  content: "\e8a3";
}

.icon-percent:before {
  content: "\e8a4";
}

.icon-phone-call:before {
  content: "\e8a5";
}

.icon-phone-forwarded:before {
  content: "\e8a6";
}

.icon-phone-missed:before {
  content: "\e8a7";
}

.icon-phone-off:before {
  content: "\e8a8";
}

.icon-phone-incoming:before {
  content: "\e8a9";
}

.icon-phone:before {
  content: "\e8aa";
}

.icon-phone-outgoing:before {
  content: "\e8ab";
}

.icon-pie-chart:before {
  content: "\e8ac";
}

.icon-play-circle:before {
  content: "\e8ad";
}

.icon-play:before {
  content: "\e8ae";
}

.icon-plus-square:before {
  content: "\e8af";
}

.icon-plus-circle:before {
  content: "\e8b0";
}

.icon-plus:before {
  content: "\e8b1";
}

.icon-pocket:before {
  content: "\e8b2";
}

.icon-printer:before {
  content: "\e8b3";
}

.icon-power:before {
  content: "\e8b4";
}

.icon-radio:before {
  content: "\e8b5";
}

.icon-repeat:before {
  content: "\e8b6";
}

.icon-refresh-ccw:before {
  content: "\e8b7";
}

.icon-rewind:before {
  content: "\e8b8";
}

.icon-rotate-ccw:before {
  content: "\e8b9";
}

.icon-refresh-cw:before {
  content: "\e8ba";
}

.icon-rotate-cw:before {
  content: "\e8bb";
}

.icon-save:before {
  content: "\e8bc";
}

.icon-search:before {
  content: "\e8bd";
}

.icon-server:before {
  content: "\e8be";
}

.icon-scissors:before {
  content: "\e8bf";
}

.icon-share-2:before {
  content: "\e8c0";
}

.icon-share:before {
  content: "\e8c1";
}

.icon-shield:before {
  content: "\e8c2";
}

.icon-settings:before {
  content: "\e8c3";
}

.icon-skip-back:before {
  content: "\e8c4";
}

.icon-shuffle:before {
  content: "\e8c5";
}

.icon-sidebar:before {
  content: "\e8c6";
}

.icon-skip-forward:before {
  content: "\e8c7";
}

.icon-slack:before {
  content: "\e8c8";
}

.icon-slash:before {
  content: "\e8c9";
}

.icon-smartphone:before {
  content: "\e8ca";
}

.icon-square:before {
  content: "\e8cb";
}

.icon-speaker:before {
  content: "\e8cc";
}

.icon-star:before {
  content: "\e8cd";
}

.icon-stop-circle:before {
  content: "\e8ce";
}

.icon-sun:before {
  content: "\e8cf";
}

.icon-sunrise:before {
  content: "\e8d0";
}

.icon-tablet:before {
  content: "\e8d1";
}

.icon-tag:before {
  content: "\e8d2";
}

.icon-sunset:before {
  content: "\e8d3";
}

.icon-target:before {
  content: "\e8d4";
}

.icon-thermometer:before {
  content: "\e8d5";
}

.icon-thumbs-up:before {
  content: "\e8d6";
}

.icon-thumbs-down:before {
  content: "\e8d7";
}

.icon-toggle-left:before {
  content: "\e8d8";
}

.icon-toggle-right:before {
  content: "\e8d9";
}

.icon-trash-2:before {
  content: "\e8da";
}

.icon-trash:before {
  content: "\e8db";
}

.icon-trending-up:before {
  content: "\e8dc";
}

.icon-trending-down:before {
  content: "\e8dd";
}

.icon-triangle:before {
  content: "\e8de";
}

.icon-type:before {
  content: "\e8df";
}

.icon-twitter:before {
  content: "\e8e0";
}

.icon-upload:before {
  content: "\e8e1";
}

.icon-umbrella:before {
  content: "\e8e2";
}

.icon-upload-cloud:before {
  content: "\e8e3";
}

.icon-unlock:before {
  content: "\e8e4";
}

.icon-user-check:before {
  content: "\e8e5";
}

.icon-user-minus:before {
  content: "\e8e6";
}

.icon-user-plus:before {
  content: "\e8e7";
}

.icon-user-x:before {
  content: "\e8e8";
}

.icon-user:before {
  content: "\e8e9";
}

.icon-users:before {
  content: "\e8ea";
}

.icon-video-off:before {
  content: "\e8eb";
}

.icon-video:before {
  content: "\e8ec";
}

.icon-voicemail:before {
  content: "\e8ed";
}

.icon-volume-x:before {
  content: "\e8ee";
}

.icon-volume-2:before {
  content: "\e8ef";
}

.icon-volume-1:before {
  content: "\e8f0";
}

.icon-volume:before {
  content: "\e8f1";
}

.icon-watch:before {
  content: "\e8f2";
}

.icon-wifi:before {
  content: "\e8f3";
}

.icon-x-square:before {
  content: "\e8f4";
}

.icon-wind:before {
  content: "\e8f5";
}

.icon-x:before {
  content: "\e8f6";
}

.icon-x-circle:before {
  content: "\e8f7";
}

.icon-zap:before {
  content: "\e8f8";
}

.icon-zoom-in:before {
  content: "\e8f9";
}

.icon-zoom-out:before {
  content: "\e8fa";
}

.icon-command:before {
  content: "\e8fb";
}

.icon-cloud:before {
  content: "\e8fc";
}

.icon-hash:before {
  content: "\e8fd";
}

.icon-headphones:before {
  content: "\e8fe";
}

.icon-underline:before {
  content: "\e8ff";
}

.icon-italic:before {
  content: "\e900";
}

.icon-bold:before {
  content: "\e901";
}

.icon-crop:before {
  content: "\e902";
}

.icon-help-circle:before {
  content: "\e903";
}

.icon-paperclip:before {
  content: "\e904";
}

.icon-shopping-cart:before {
  content: "\e905";
}

.icon-tv:before {
  content: "\e906";
}

.icon-wifi-off:before {
  content: "\e907";
}

.icon-minimize:before {
  content: "\e88d";
}

.icon-maximize:before {
  content: "\e908";
}

.icon-gitlab:before {
  content: "\e909";
}

.icon-sliders:before {
  content: "\e90a";
}

.icon-star-on:before {
  content: "\e90b";
}

.icon-heart-on:before {
  content: "\e90c";
}

.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: 0.08em solid #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fab.fa-pull-left,
.fal.fa-pull-left,
.far.fa-pull-left,
.fas.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right,
.fab.fa-pull-right,
.fal.fa-pull-right,
.far.fa-pull-right,
.fas.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-horizontal.fa-flip-vertical,
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270 {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adobe:before {
  content: "\f778";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-fly:before {
  content: "\f417";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-haykal:before {
  content: "\f666";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-key:before {
  content: "\f084";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-nintendo-switch:before {
  content: "\f418";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-parking:before {
  content: "\f540";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-print:before {
  content: "\f02f";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-water:before {
  content: "\f773";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/fa-brands-400.eot);
  src: url(../fonts/fa-brands-400.eot?#iefix) format("embedded-opentype"),
    url(../fonts/fa-brands-400.woff2) format("woff2"),
    url(../fonts/fa-brands-400.woff) format("woff"),
    url(../fonts/fa-brands-400.ttf) format("truetype"),
    url(../fonts/fa-brands-400.svg#fontawesome) format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/fa-regular-400.eot);
  src: url(../fonts/fa-regular-400.eot?#iefix) format("embedded-opentype"),
    url(../fonts/fa-regular-400.woff2) format("woff2"),
    url(../fonts/fa-regular-400.woff) format("woff"),
    url(../fonts/fa-regular-400.ttf) format("truetype"),
    url(../fonts/fa-regular-400.svg#fontawesome) format("svg");
}

.far {
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/fa-solid-900.eot);
  src: url(../fonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),
    url(../fonts/fa-solid-900.woff2) format("woff2"),
    url(../fonts/fa-solid-900.woff) format("woff"),
    url(../fonts/fa-solid-900.ttf) format("truetype"),
    url(../fonts/fa-solid-900.svg#fontawesome) format("svg");
}

.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free";
}

.fa,
.fas {
  font-weight: 900;
}

/**  =====================
      Custom css start
==========================  **/
/* ==========  card css start  =========== */
.anim-rotate {
  -webkit-animation: anim-rotate 1s linear infinite;
  animation: anim-rotate 1s linear infinite;
}

@-webkit-keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.anim-close-card {
  -webkit-animation: anim-close-card 1.4s linear;
  animation: anim-close-card 1.4s linear;
}

@-webkit-keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.card {
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out;
}

.card:hover {
  box-shadow: 0 5px 11px 0px rgba(69, 90, 100, 0.3);
}

.card .card-header {
  border-bottom: 1px solid #e2e5e8;
  position: relative;
}

.card .card-header h5 {
  margin-bottom: 0;
  color: #37474f;
  font-size: 0.9375rem;
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.1;
  position: relative;
}

.card .card-header .card-header-right {
  right: 10px;
  top: 10px;
  display: inline-block;
  float: right;
  padding: 0;
  position: absolute;
}

@media only screen and (max-width: 575px) {
  .card .card-header .card-header-right {
    display: none;
  }
}

.card .card-header .card-header-right .dropdown-menu {
  margin-top: 0;
}

.card .card-header .card-header-right .dropdown-menu li {
  cursor: pointer;
}

.card .card-header .card-header-right .dropdown-menu li a {
  font-size: 14px;
  text-transform: capitalize;
}

.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #888;
}

.card .card-header .card-header-right .btn.dropdown-toggle i {
  margin-right: 0;
}

.card .card-header .card-header-right .btn.dropdown-toggle:after {
  display: none;
}

.card .card-header .card-header-right .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 8px;
}

.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span {
  background-color: #888;
  height: 2px;
  border-radius: 5px;
}

.card
  .card-header
  .card-header-right
  .btn.dropdown-toggle.mobile-menu
  span:after,
.card
  .card-header
  .card-header-right
  .btn.dropdown-toggle.mobile-menu
  span:before {
  border-radius: 5px;
  height: 2px;
  background-color: #888;
}

.card .card-header .card-header-right .nav-pills {
  padding: 0;
  box-shadow: none;
  background: transparent;
}

.card .card-footer {
  border-top: 1px solid #e2e5e8;
  padding: 12px 20px;
}

.card .card-footer:not([class*="bg-"]) {
  background: transparent;
}

.card .card-block,
.card .card-body {
  padding: 20px 20px;
}

.card.card-load {
  position: relative;
  overflow: hidden;
}

.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
}

.card.card-load .card-loader i {
  margin: 0 auto;
  color: #4680ff;
  font-size: 24px;
  align-items: center;
  display: flex;
}

.card.full-card {
  z-index: 99999;
  border-radius: 0;
}

.card[class*="bg-"] .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

/**  =====================
      button css start
==========================  **/
.btn-page .btn {
  margin-right: 5px;
  margin-bottom: 5px;
}

.btn-page .btn-group .btn,
.btn-page .btn-group-vertical .btn {
  margin-right: 0;
  margin-bottom: 0;
}

.btn.btn-icon {
  width: 25px;
  height: 25px;
  padding: 0;
  border-radius: 50%;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/**  =====================
      Radio & Checked css start
==========================  **/
.custom-checkbox .custom-control-label:before {
  top: 0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.125rem;
}

.custom-checkbox .custom-control-label:after {
  content: "";
  display: block;
  width: 6px;
  height: 12px;
  opacity: 0.9;
  background-image: none;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  position: absolute;
  left: -21px;
  top: 10px;
  transform: scaleX(-1) rotate(135deg);
  transform-origin: left top;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  -webkit-animation: custripple 0.3s linear forwards;
  animation: custripple 0.3s linear forwards;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  -webkit-animation: check linear 0.5s;
  animation: check linear 0.5s;
  opacity: 1;
}

.custom-radio .custom-control-label:after,
.custom-radio .custom-control-label:before {
  top: 2px;
  width: 1.2rem;
  height: 1.2rem;
}

.custom-radio .custom-control-label:after {
  transform: scale(0);
  opacity: 0;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  -webkit-animation: custripple 0.3s linear forwards;
  animation: custripple 0.3s linear forwards;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  opacity: 1;
  transform: scale(0.4);
}

.custom-switch .custom-control-label:before {
  left: -2.25rem;
  top: 0.35rem;
  width: 1.75rem;
  height: 0.8rem;
  pointer-events: all;
  border-radius: 0.5rem;
  background: #dbe3ec;
  border: none;
  box-shadow: none !important;
}

.custom-switch .custom-control-label:after {
  top: 3px;
  left: -37px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background: #aec7ff;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-animation: custripple 0.3s linear forwards;
  animation: custripple 0.3s linear forwards;
  background: #4680ff;
}

@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0;
  }

  25% {
    height: 0;
    width: 6px;
  }

  50% {
    height: 12px;
    width: 6px;
  }
}

@keyframes check {
  0% {
    height: 0;
    width: 0;
  }

  25% {
    height: 0;
    width: 6px;
  }

  50% {
    height: 12px;
    width: 6px;
  }
}

@-webkit-keyframes custripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.08);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

@keyframes custripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.08);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

/**====== Radio & Checked css end ======**/
/**  =====================
      Switches css start
==========================  **/
.switch input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch input[type="checkbox"] + .cr {
  position: relative;
  display: inline-block;
  transition: 0.4s ease;
  height: 20px;
  width: 35px;
  border: 1px solid #e9eaec;
  border-radius: 60px;
  cursor: pointer;
  z-index: 0;
  top: 12px;
}

.switch input[type="checkbox"] + .cr:after,
.switch input[type="checkbox"] + .cr:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.switch input[type="checkbox"] + .cr:before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 30px;
}

.switch input[type="checkbox"] + .cr:after {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #f7f7f7;
  height: 19px;
  width: 19px;
  border-radius: 60px;
}

.switch input[type="checkbox"]:checked + .cr:before {
  background: #4680ff;
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.switch input[type="checkbox"]:checked + .cr:after {
  left: 16px;
}

.switch input[type="checkbox"]:disabled + label {
  opacity: 0.5;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
  cursor: not-allowed;
}

.switch.switch-primary input[type="checkbox"]:checked + .cr:before {
  background: #4680ff;
}

.switch.switch-danger input[type="checkbox"]:checked + .cr:before {
  background: #ff5252;
}

.switch.switch-success input[type="checkbox"]:checked + .cr:before {
  background: #9ccc65;
}

.switch.switch-warning input[type="checkbox"]:checked + .cr:before {
  background: #ffba57;
}

.switch.switch-info input[type="checkbox"]:checked + .cr:before {
  background: #00bcd4;
}

.switch.switch-purple input[type="checkbox"]:checked + .cr:before {
  background: #536dfe;
}

/**====== Switches css end ======**/
/**  =====================
      Badges css start
==========================  **/
.badge {
  font-size: 80%;
  padding: 0.35em 0.6em;
  font-weight: 600;
}

.badge-light-primary {
  background: rgba(70, 128, 255, 0.2);
  border-color: #cbdbff;
  color: #4680ff;
}

a.badge-light-primary.active,
a.badge-light-primary:active,
a.badge-light-primary:focus,
a.badge-light-primary:hover {
  background: #4680ff;
  border-color: #4680ff;
  color: #dae6ff;
}

.badge-light-secondary {
  background: rgba(108, 117, 125, 0.2);
  border-color: #d6d8db;
  color: #6c757d;
}

a.badge-light-secondary.active,
a.badge-light-secondary:active,
a.badge-light-secondary:focus,
a.badge-light-secondary:hover {
  background: #6c757d;
  border-color: #6c757d;
  color: #e2e3e5;
}

.badge-light-success {
  background: rgba(156, 204, 101, 0.2);
  border-color: #e3f1d4;
  color: #9ccc65;
}

a.badge-light-success.active,
a.badge-light-success:active,
a.badge-light-success:focus,
a.badge-light-success:hover {
  background: #9ccc65;
  border-color: #9ccc65;
  color: #ebf5e0;
}

.badge-light-info {
  background: rgba(0, 172, 193, 0.2);
  border-color: #b8e8ee;
  color: #00acc1;
}

a.badge-light-info.active,
a.badge-light-info:active,
a.badge-light-info:focus,
a.badge-light-info:hover {
  background: #00acc1;
  border-color: #00acc1;
  color: #cceef3;
}

.badge-light-warning {
  background: rgba(255, 186, 87, 0.2);
  border-color: #ffecd0;
  color: #ffba57;
}

a.badge-light-warning.active,
a.badge-light-warning:active,
a.badge-light-warning:focus,
a.badge-light-warning:hover {
  background: #ffba57;
  border-color: #ffba57;
  color: #fff1dd;
}

.badge-light-danger {
  background: rgba(255, 82, 82, 0.2);
  border-color: #ffcfcf;
  color: #ff5252;
}

a.badge-light-danger.active,
a.badge-light-danger:active,
a.badge-light-danger:focus,
a.badge-light-danger:hover {
  background: #ff5252;
  border-color: #ff5252;
  color: #ffdcdc;
}

.badge-light-light {
  background: rgba(248, 249, 250, 0.2);
  border-color: #fdfdfe;
  color: #f8f9fa;
}

a.badge-light-light.active,
a.badge-light-light:active,
a.badge-light-light:focus,
a.badge-light-light:hover {
  background: #f8f9fa;
  border-color: #f8f9fa;
  color: #fefefe;
}

.badge-light-dark {
  background: rgba(52, 58, 64, 0.2);
  border-color: #c6c8ca;
  color: #343a40;
}

a.badge-light-dark.active,
a.badge-light-dark:active,
a.badge-light-dark:focus,
a.badge-light-dark:hover {
  background: #343a40;
  border-color: #343a40;
  color: #d6d8d9;
}

/**====== Badges css end ======**/
/**  =====================
      Custom css start
==========================  **/
body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #373a3c;
  font-weight: 400;
  background: #4680ff;
  position: relative;
}

*:focus {
  outline: none;
}

a:hover {
  outline: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #37474f;
  font-weight: 600;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 13px;
}

b,
strong {
  font-weight: 600;
}

.img-radius {
  border-radius: 50%;
}

.page-header {
  background: transparent;
  margin-top: -140px;
  margin-bottom: 30px;
}

.page-header h5 {
  margin-bottom: 15px;
  font-weight: 400;
  color: #fff;
}

.page-header .page-header-title + .breadcrumb {
  background: transparent;
  padding: 0;
  margin-bottom: 0;
}

.page-header .page-header-title + .breadcrumb > .breadcrumb-item {
  line-height: 1;
}

.page-header .page-header-title + .breadcrumb > .breadcrumb-item:before {
  color: rgba(255, 255, 255, 0.5);
}

.page-header .page-header-title + .breadcrumb > .breadcrumb-item a {
  color: #fff;
  font-weight: 400;
}

.page-header .page-header-title + .breadcrumb > .breadcrumb-item:last-child a {
  color: #fff;
  font-weight: 600;
}

.page-header:not(.breadcumb-sticky)
  .page-header-title
  + .breadcrumb
  > .breadcrumb-item
  a {
  font-size: 13px;
}

.page-header.breadcumb-sticky {
  overflow: hidden;
  position: relative;
}

.page-header.breadcumb-sticky .page-header-title {
  display: inline-block;
}

.page-header.breadcumb-sticky .page-header-title h5 {
  margin-bottom: 0;
  color: #fff;
}

.page-header.breadcumb-sticky .breadcrumb-item + .breadcrumb-item::before,
.page-header.breadcumb-sticky
  .page-header-title
  + .breadcrumb
  > .breadcrumb-item
  a,
.page-header.breadcumb-sticky
  .page-header-title
  + .breadcrumb
  > .breadcrumb-item:last-child
  a {
  color: #fff;
}

.page-header.breadcumb-sticky .breadcrumb {
  display: inline-flex;
  float: right;
  margin-top: 4px;
}

@media only screen and (max-width: 767px) {
  .page-header.breadcumb-sticky .breadcrumb {
    display: flex;
    float: none;
  }
}

/* ==========  card css start  =========== */
.anim-rotate {
  -webkit-animation: anim-rotate 1s linear infinite;
  animation: anim-rotate 1s linear infinite;
}

@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.anim-close-card {
  -webkit-animation: anim-close-card 1.4s linear;
  animation: anim-close-card 1.4s linear;
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

/* ==========  scrollbar End  =========== */
.scroll-y {
  z-index: 1027;
}

/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */
.dropdown-menu {
  padding: 10px 0;
  font-size: 14px;
}

.dropdown-menu > li,
.dropdown-menu > .dropdown-item {
  padding-left: 8px;
  padding-right: 8px;
  margin: 0 10px;
  width: auto;
  border-radius: 2px;
}

.dropdown-menu > li > a,
.dropdown-menu > .dropdown-item > a {
  padding: 5px;
  color: #373a3c;
}

.dropdown-menu > li > a i,
.dropdown-menu > .dropdown-item > a i {
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.dropdown-menu > li.active,
.dropdown-menu > li:active,
.dropdown-menu > li:focus,
.dropdown-menu > li:hover,
.dropdown-menu > .dropdown-item.active,
.dropdown-menu > .dropdown-item:active,
.dropdown-menu > .dropdown-item:focus,
.dropdown-menu > .dropdown-item:hover {
  background: #4680ff;
  color: #fff;
  box-shadow: 0 0 4px 0 rgba(43, 43, 43, 0.25);
}

.dropdown-menu > li.active > a,
.dropdown-menu > li:active > a,
.dropdown-menu > li:focus > a,
.dropdown-menu > li:hover > a,
.dropdown-menu > .dropdown-item.active > a,
.dropdown-menu > .dropdown-item:active > a,
.dropdown-menu > .dropdown-item:focus > a,
.dropdown-menu > .dropdown-item:hover > a {
  background: transparent;
  color: #fff;
}

.dropdown-menu > a.active,
.dropdown-menu > a:active,
.dropdown-menu > a:focus,
.dropdown-menu > a:hover {
  background: #4680ff;
  color: #fff;
}

.drp-icon:after {
  display: none;
}

/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}

/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/
.bd-example-row {
  background: #ecf0f5;
  padding: 15px;
  margin-bottom: 15px;
}

.bd-example-row .row > [class^="col-"],
.bd-example-row .row > .col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.bd-example-row .row + .row {
  margin-top: 1rem;
}

.bd-example-row .flex-items-bottom,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-top {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

/* Docs examples */
.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0;
}

@media only screen and (max-height: 575px) {
  .bd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}

/* Example modals */
.modal {
  z-index: 1072;
}

.modal .popover,
.modal .tooltip {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: #fafafa;
}

.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.bd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}

/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}

/* Code snippets */
.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media only screen and (max-height: 575px) {
  .highlight {
    padding: 1.5rem;
  }
}

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px;
}

@media only screen and (max-height: 575px) {
  .bd-content .highlight {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}

.highlight pre code {
  font-size: inherit;
  color: #333;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: transparent;
}

/* =======================================================================
 ===============     Ui kit copy model style      ====================== */
/* Code examples */
.datta-example {
  position: relative;
}

.datta-example .datta-example-btns {
  position: absolute;
  right: 30px;
  top: -10px;
  opacity: 0;
  transform: rotateX(-90deg);
  transition: all 0.3s ease-in-out;
}

.datta-example .datta-example-btns .datta-example-btn {
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  background: #4680ff;
  color: #fff;
  padding: 0.1875rem 0.3125rem;
  border-radius: 2px;
  white-space: nowrap;
  font-size: 11px;
  margin: 0 4px;
}

.datta-example .datta-example-btns .datta-example-btn.copy::before {
  content: "COPY";
}

.datta-example .datta-example-btns .datta-example-btn.copied {
  background: #9ccc65 !important;
  color: #fff !important;
}

.datta-example .datta-example-btns .datta-example-btn.copied::before {
  content: "COPIED!";
}

.datta-example:hover .datta-example-btns {
  top: -21px;
  transform: rotateX(0deg);
  opacity: 1;
}

/* Modal */
.datta-example-modal {
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #282c34;
  transform-origin: 50% 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
  overflow: hidden !important;
}

.datta-example-modal-opened .datta-example-modal {
  transform: scale(1);
}

.datta-example-modal-content {
  max-width: 100vw;
  margin: auto;
  padding: 50px;
  height: 100vh;
  overflow: auto;
}

.datta-example-modal-content > pre {
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.datta-example-modal-content > pre > code {
  padding: 0;
  background: none;
  font-size: 16px;
}

.md-datta-example-modal-copy {
  display: block;
  position: fixed;
  top: 90px;
  right: 30px;
  margin-right: 8px;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 10px 15px;
  background: #4680ff;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;
}

.md-datta-example-modal-copy:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
}

.md-datta-example-modal-copy.copied::before {
  content: "Copied to Clipboard Successfully ! . . .";
  position: absolute;
  display: block;
  right: 100%;
  margin-right: 10px;
  font-size: 14px;
  background: #9ccc65;
  line-height: 24px;
  height: 24px;
  border-radius: 3px;
  padding: 0 6px;
  top: 50%;
  margin-top: -12px;
}

.datta-example-modal-close {
  display: block;
  position: fixed;
  top: 10px;
  right: 52px;
  color: #fff;
  opacity: 0.2;
  font-size: 3rem;
  font-weight: 100;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.datta-example-modal-close:hover {
  color: #fff;
  opacity: 0.9;
}

/* Code */
.cui-bottom-spacer {
  height: 12rem;
}

/* editor style for model */
.hljs {
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

/* ================================    md animated mode start   ===================== */
.md-show.md-effect-12 ~ .md-overlay {
  background: #4680ff;
}

/* ================================    md animated mode End   ===================== */
/* ================================    range slider Start  ===================== */
.tooltip.in {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

/* ================================    range slider End  ===================== */
/* ================================    owl-carousel slider Start  ===================== */
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px;
}

/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */
.bootstrap-tagsinput {
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 2px;
  background: #ecf0f5;
}

.bootstrap-tagsinput .tag {
  padding: 5px 12px;
  border-radius: 2px;
  line-height: 37px;
  margin-top: 5px;
  margin-right: 5px;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-right: -5px;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "\e8f6";
  padding: 0 2px;
  font-family: "feather" !important;
}

/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */
.ms-container {
  width: 100%;
}

/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */
.blockquote {
  padding: 0.5rem 0.5rem;
}

.blockquote.text-right {
  border-left: none;
  border-right: 0.25rem solid #e2e5e8;
}

/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */
.card .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  border: 5px solid #fff;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */
.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}

.ie-warning p {
  font-size: 17px;
}

.ie-warning h1 {
  color: #fff;
}

.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}

.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}

.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}

.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}

.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}

.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}

/* ================================    browser  warning  End  ===================== */
div.code-toolbar > .toolbar a,
div.code-toolbar > .toolbar span,
div.code-toolbar > .toolbar button {
  padding: 3px 0.9em !important;
  background: #04a9f5 !important;
  color: #fff !important;
  box-shadow: none !important;
}

pre[class*="language-"]:after,
pre[class*="language-"]:before {
  display: none;
}

/* table css */
.table-borderless tbody tr td,
.table-borderless tbody tr th {
  border: 0;
}

@media only screen and (max-width: 400px) {
  .lay-customizer .theme-color > a[data-value="reset"],
  .theme-color > a[data-value="reset"] {
    margin-top: 20px;
  }
}

/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px;
}

.p-t-0 {
  padding-top: 0px;
}

.p-b-0 {
  padding-bottom: 0px;
}

.p-l-0 {
  padding-left: 0px;
}

.p-r-0 {
  padding-right: 0px;
}

.m-0 {
  margin: 0px;
}

.m-t-0 {
  margin-top: 0px;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.m-r-0 {
  margin-right: 0px;
}

.p-5 {
  padding: 5px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.m-5 {
  margin: 5px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.p-10 {
  padding: 10px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.m-10 {
  margin: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.p-15 {
  padding: 15px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.m-15 {
  margin: 15px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.p-20 {
  padding: 20px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.m-20 {
  margin: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.p-25 {
  padding: 25px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-r-25 {
  padding-right: 25px;
}

.m-25 {
  margin: 25px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-r-25 {
  margin-right: 25px;
}

.p-30 {
  padding: 30px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-r-30 {
  padding-right: 30px;
}

.m-30 {
  margin: 30px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.p-35 {
  padding: 35px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-b-35 {
  padding-bottom: 35px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-r-35 {
  padding-right: 35px;
}

.m-35 {
  margin: 35px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-r-35 {
  margin-right: 35px;
}

.p-40 {
  padding: 40px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-r-40 {
  padding-right: 40px;
}

.m-40 {
  margin: 40px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-r-40 {
  margin-right: 40px;
}

.p-45 {
  padding: 45px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-r-45 {
  padding-right: 45px;
}

.m-45 {
  margin: 45px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-r-45 {
  margin-right: 45px;
}

.p-50 {
  padding: 50px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.p-l-50 {
  padding-left: 50px;
}

.p-r-50 {
  padding-right: 50px;
}

.m-50 {
  margin: 50px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-r-50 {
  margin-right: 50px;
}

/*====== Padding , Margin css ends ======*/
/*====== text-color, background & border css starts ======*/
.bg-c-blue {
  background-color: #4680ff;
}

.text-c-blue {
  color: #4680ff;
}

.b-c-blue {
  border: 1px solid #4680ff;
}

.bg-c-red {
  background-color: #ff5252;
}

.text-c-red {
  color: #ff5252;
}

.b-c-red {
  border: 1px solid #ff5252;
}

.bg-c-green {
  background-color: #9ccc65;
}

.text-c-green {
  color: #9ccc65;
}

.b-c-green {
  border: 1px solid #9ccc65;
}

.bg-c-yellow {
  background-color: #ffba57;
}

.text-c-yellow {
  color: #ffba57;
}

.b-c-yellow {
  border: 1px solid #ffba57;
}

.bg-c-purple {
  background-color: #536dfe;
}

.text-c-purple {
  color: #536dfe;
}

.b-c-purple {
  border: 1px solid #536dfe;
}

/*====== text-color, background & border css ends ======*/
/*====== [ overrides bootstrap color ]text-color, background & border css starts ======*/
.bg-primary {
  background-color: #4680ff !important;
}

.text-primary {
  color: #4680ff !important;
}

.b-primary {
  border: 1px solid #4680ff !important;
}

.text-h-primary:hover {
  color: #4680ff !important;
}

.bg-danger {
  background-color: #ff5252 !important;
}

.text-danger {
  color: #ff5252 !important;
}

.b-danger {
  border: 1px solid #ff5252 !important;
}

.text-h-danger:hover {
  color: #ff5252 !important;
}

.bg-success {
  background-color: #9ccc65 !important;
}

.text-success {
  color: #9ccc65 !important;
}

.b-success {
  border: 1px solid #9ccc65 !important;
}

.text-h-success:hover {
  color: #9ccc65 !important;
}

.bg-warning {
  background-color: #ffba57 !important;
}

.text-warning {
  color: #ffba57 !important;
}

.b-warning {
  border: 1px solid #ffba57 !important;
}

.text-h-warning:hover {
  color: #ffba57 !important;
}

.bg-info {
  background-color: #00bcd4 !important;
}

.text-info {
  color: #00bcd4 !important;
}

.b-info {
  border: 1px solid #00bcd4 !important;
}

.text-h-info:hover {
  color: #00bcd4 !important;
}

.bg-purple {
  background-color: #536dfe !important;
}

.text-purple {
  color: #536dfe !important;
}

.b-purple {
  border: 1px solid #536dfe !important;
}

.text-h-purple:hover {
  color: #536dfe !important;
}

/*====== [ overrides bootstrap color ]text-color, background & border css ends ======*/
/*====== border color css starts ======*/
.b-primary {
  border: 1px solid #4680ff;
}

.border-bottom-primary td {
  border-bottom: 1px solid #4680ff;
}

.border-bottom-primary th {
  border-bottom: 1px solid #4680ff !important;
}

.b-danger {
  border: 1px solid #ff5252;
}

.border-bottom-danger td {
  border-bottom: 1px solid #ff5252;
}

.border-bottom-danger th {
  border-bottom: 1px solid #ff5252 !important;
}

.b-success {
  border: 1px solid #9ccc65;
}

.border-bottom-success td {
  border-bottom: 1px solid #9ccc65;
}

.border-bottom-success th {
  border-bottom: 1px solid #9ccc65 !important;
}

.b-warning {
  border: 1px solid #ffba57;
}

.border-bottom-warning td {
  border-bottom: 1px solid #ffba57;
}

.border-bottom-warning th {
  border-bottom: 1px solid #ffba57 !important;
}

.b-info {
  border: 1px solid #00bcd4;
}

.border-bottom-info td {
  border-bottom: 1px solid #00bcd4;
}

.border-bottom-info th {
  border-bottom: 1px solid #00bcd4 !important;
}

.b-purple {
  border: 1px solid #536dfe;
}

.border-bottom-purple td {
  border-bottom: 1px solid #536dfe;
}

.border-bottom-purple th {
  border-bottom: 1px solid #536dfe !important;
}

/*====== border color css ends ======*/
/*====== Card top border css starts ======*/
.card-border-c-blue {
  border-top: 4px solid #4680ff;
}

.card-border-c-red {
  border-top: 4px solid #ff5252;
}

.card-border-c-green {
  border-top: 4px solid #9ccc65;
}

.card-border-c-yellow {
  border-top: 4px solid #ffba57;
}

.card-border-c-purple {
  border-top: 4px solid #536dfe;
}

/*====== Card top border ends ======*/
/*====== Font-size css starts ======*/
.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-200 {
  font-weight: 200;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/
/*====== text-color, background color css starts ======*/
.bg-facebook {
  background: #3c5a99;
}

.text-facebook {
  color: #3c5a99;
}

.bg-twitter {
  background: #42c0fb;
}

.text-twitter {
  color: #42c0fb;
}

.bg-dribbble {
  background: #ec4a89;
}

.text-dribbble {
  color: #ec4a89;
}

.bg-pinterest {
  background: #bf2131;
}

.text-pinterest {
  color: #bf2131;
}

.bg-youtube {
  background: #e0291d;
}

.text-youtube {
  color: #e0291d;
}

.bg-googleplus {
  background: #c73e2e;
}

.text-googleplus {
  color: #c73e2e;
}

.bg-instagram {
  background: #aa7c62;
}

.text-instagram {
  color: #aa7c62;
}

.bg-viber {
  background: #7b519d;
}

.text-viber {
  color: #7b519d;
}

.bg-behance {
  background: #0057ff;
}

.text-behance {
  color: #0057ff;
}

.bg-dropbox {
  background: #3380ff;
}

.text-dropbox {
  color: #3380ff;
}

.bg-linkedin {
  background: #0077b5;
}

.text-linkedin {
  color: #0077b5;
}

/*====== text-color css ends ======*/
/*====== width, Height css starts ======*/
.wid-20 {
  width: 20px;
}

.hei-20 {
  height: 20px;
}

.wid-25 {
  width: 25px;
}

.hei-25 {
  height: 25px;
}

.wid-30 {
  width: 30px;
}

.hei-30 {
  height: 30px;
}

.wid-35 {
  width: 35px;
}

.hei-35 {
  height: 35px;
}

.wid-40 {
  width: 40px;
}

.hei-40 {
  height: 40px;
}

.wid-45 {
  width: 45px;
}

.hei-45 {
  height: 45px;
}

.wid-50 {
  width: 50px;
}

.hei-50 {
  height: 50px;
}

.wid-55 {
  width: 55px;
}

.hei-55 {
  height: 55px;
}

.wid-60 {
  width: 60px;
}

.hei-60 {
  height: 60px;
}

.wid-65 {
  width: 65px;
}

.hei-65 {
  height: 65px;
}

.wid-70 {
  width: 70px;
}

.hei-70 {
  height: 70px;
}

.wid-75 {
  width: 75px;
}

.hei-75 {
  height: 75px;
}

.wid-80 {
  width: 80px;
}

.hei-80 {
  height: 80px;
}

.wid-85 {
  width: 85px;
}

.hei-85 {
  height: 85px;
}

.wid-90 {
  width: 90px;
}

.hei-90 {
  height: 90px;
}

.wid-95 {
  width: 95px;
}

.hei-95 {
  height: 95px;
}

.wid-100 {
  width: 100px;
}

.hei-100 {
  height: 100px;
}

.wid-105 {
  width: 105px;
}

.hei-105 {
  height: 105px;
}

.wid-110 {
  width: 110px;
}

.hei-110 {
  height: 110px;
}

.wid-115 {
  width: 115px;
}

.hei-115 {
  height: 115px;
}

.wid-120 {
  width: 120px;
}

.hei-120 {
  height: 120px;
}

.wid-125 {
  width: 125px;
}

.hei-125 {
  height: 125px;
}

.wid-130 {
  width: 130px;
}

.hei-130 {
  height: 130px;
}

.wid-135 {
  width: 135px;
}

.hei-135 {
  height: 135px;
}

.wid-140 {
  width: 140px;
}

.hei-140 {
  height: 140px;
}

.wid-145 {
  width: 145px;
}

.hei-145 {
  height: 145px;
}

.wid-150 {
  width: 150px;
}

.hei-150 {
  height: 150px;
}

/*====== width, Height css ends ======*/
/*====== border-width css starts ======*/
.b-wid-1 {
  border-width: 1px;
}

.b-wid-2 {
  border-width: 2px;
}

.b-wid-3 {
  border-width: 3px;
}

.b-wid-4 {
  border-width: 4px;
}

.b-wid-5 {
  border-width: 5px;
}

.b-wid-6 {
  border-width: 6px;
}

.b-wid-7 {
  border-width: 7px;
}

.b-wid-8 {
  border-width: 8px;
}

/*====== border-width css ends ======*/
/* new logo start */
.b-brand {
  display: flex;
  align-items: center;
}

.b-brand .b-bg {
  background: #4680ff;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
}

.b-brand .b-title {
  margin-left: 10px;
  font-weight: 400;
  color: #fff;
  font-size: 16px;
}

.navbar-collapsed .b-brand .b-title {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-collapsed .mobile-menu {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-collapsed:hover .b-brand .b-title {
  transform: rotateY(0deg);
  opacity: 1;
}

.navbar-collapsed:hover .mobile-menu {
  transition-delay: 0.3s;
  transform: rotateY(0deg);
  opacity: 1;
}

/* new logo End  */
/* Pre-loader css start */
.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1035;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
}

.loader-bg .loader-track {
  position: relative;
  height: 3px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.loader-bg .loader-track .loader-fill:after,
.loader-bg .loader-track .loader-fill:before {
  content: "";
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
}

.loader-bg .loader-track .loader-fill:before {
  -webkit-animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.loader-bg .loader-track .loader-fill:after {
  -webkit-animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -35%;
  }
}

@keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -35%;
  }
}

@-webkit-keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

/* Pre-loader css end */
/* header css start */
.search-bar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
  z-index: 1030;
  background: #fff;
  padding: 12px;
}

.search-bar .close {
  position: absolute;
  top: 9px;
  right: 9px;
  padding: 8px 16px;
}

.pcoded-header {
  z-index: 1029;
  position: relative;
  display: flex;
  min-height: 50px;
  padding: 0;
  top: 0;
  background: #fff;
  color: rgba(16, 27, 51, 0.8);
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.pcoded-header .m-header {
  width: 230px;
  position: relative;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
}
.logo {
  width: 150px;
  height: 50px;
}
.pcoded-header .m-header .logo-dark,
.pcoded-header .m-header .logo-thumb {
  display: none;
}

.pcoded-header .input-group {
  background: transparent;
}

.pcoded-header .input-group .input-group-text {
  margin-right: 0;
}

.pcoded-header .input-group .input-group-text,
.pcoded-header a,
.pcoded-header dropdown-toggle {
  color: rgba(16, 27, 51, 0.8);
}

.pcoded-header .input-group .input-group-text:hover,
.pcoded-header a:hover,
.pcoded-header dropdown-toggle:hover {
  color: #101b33;
}

.pcoded-header #mobile-header {
  display: none;
}

.pcoded-header .navbar-nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  display: inline-flex;
  flex-direction: row;
}

.pcoded-header .navbar-nav > li {
  line-height: 50px;
  display: inline-block;
}

.pcoded-header .navbar-nav > li .nav-link {
  padding: 0;
}

.pcoded-header .navbar-nav > li:first-child {
  padding-left: 25px;
}

.pcoded-header .navbar-nav > li:last-child {
  padding-right: 25px;
}

.pcoded-header .navbar-nav .dropdown {
  height: 50px;
}

.pcoded-header .mr-auto .dropdown-menu {
  margin-left: -20px;
}

.pcoded-header .ml-auto {
  float: right;
  height: 50px;
}

.pcoded-header .ml-auto .dropdown-menu {
  margin-right: -20px;
}

.pcoded-header .main-search .input-group {
  border-radius: 3px;
  padding: 0;
}

.pcoded-header .main-search .input-group .form-control,
.pcoded-header .main-search .input-group .input-group-text {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  height: auto;
}

.pcoded-header .main-search .input-group .search-close {
  display: none;
}

.pcoded-header .main-search .input-group .search-btn {
  padding: 0;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  margin-bottom: 0;
  margin-right: 0;
}

.pcoded-header .main-search .input-group .form-control {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.pcoded-header .main-search .input-group .form-control:active,
.pcoded-header .main-search .input-group .form-control:focus,
.pcoded-header .main-search .input-group .form-control:hover,
.pcoded-header .main-search .input-group .search-btn:active,
.pcoded-header .main-search .input-group .search-btn:focus,
.pcoded-header .main-search .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}

.pcoded-header .main-search.open .input-group {
  background: #ecf0f5;
  border: 0 solid #ecf0f5;
  padding: 0 0 0 20px;
}

.pcoded-header .main-search.open .input-group .search-close {
  display: flex;
}

.pcoded-header .main-search.open .input-group .search-btn {
  padding: 6px 9px;
  margin-left: 5px;
  color: #fff;
  background: #4680ff;
  border-color: #4680ff;
}

.pcoded-header .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}

.pcoded-header .main-search.open .input-group .form-control {
  transition: all 0.3s ease-in-out;
}

.pcoded-header .main-search.open .input-group .form-control:active,
.pcoded-header .main-search.open .input-group .form-control:focus,
.pcoded-header .main-search.open .input-group .form-control:hover,
.pcoded-header .main-search.open .input-group .search-btn:active,
.pcoded-header .main-search.open .input-group .search-btn:focus,
.pcoded-header .main-search.open .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}

.pcoded-header .dropdown .dropdown-toggle {
  line-height: 50px;
  display: inline-block;
  text-align: center;
  width: 40px;
}

.pcoded-header .dropdown .dropdown-toggle:after {
  display: none;
}

.pcoded-header .dropdown .dropdown-menu {
  position: absolute;
  min-width: 250px;
}

.pcoded-header .dropdown .dropdown-menu li {
  line-height: 1.2;
}

.pcoded-header .dropdown .dropdown-menu li a {
  padding: 10px;
  font-size: 14px;
}

.pcoded-header .dropdown .dropdown-menu li.active a,
.pcoded-header .dropdown .dropdown-menu li:active a,
.pcoded-header .dropdown .dropdown-menu li:focus a,
.pcoded-header .dropdown .dropdown-menu li:hover a {
  color: #373a3c;
}

.pcoded-header .dropdown .notification {
  width: 450px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}

.pcoded-header .dropdown .notification a {
  color: #373a3c;
}

.pcoded-header .dropdown .notification a:hover {
  color: #37474f;
}

.pcoded-header .dropdown .notification .noti-head {
  background: #101b33;
  padding: 15px 20px;
}

.pcoded-header .dropdown .notification .noti-head h6 {
  color: #fff;
}

.pcoded-header .dropdown .notification .noti-head a {
  color: #fff;
  text-decoration: underline;
  font-size: 13px;
}

.pcoded-header .dropdown .notification .noti-body {
  padding: 0;
  position: relative;
  max-height: 550px;
  height: auto;
  overflow-y: auto;
}

.pcoded-header .dropdown .notification .noti-body img {
  width: 40px;
  margin-right: 20px;
}

.pcoded-header .dropdown .notification .noti-body li {
  padding: 10px 5px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.pcoded-header .dropdown .notification .noti-body li.n-title {
  padding-bottom: 0;
}

.pcoded-header .dropdown .notification .noti-body li.n-title p {
  margin-bottom: 5px;
}

.pcoded-header .dropdown .notification .noti-body li.notification:hover {
  background: rgba(70, 128, 255, 0.1);
}

.pcoded-header .dropdown .notification .noti-body li p {
  margin-bottom: 5px;
  font-size: 13px;
}

.pcoded-header .dropdown .notification .noti-body li p strong {
  color: #222;
}

.pcoded-header .dropdown .notification .noti-body li .n-time {
  font-size: 80%;
  float: right;
}

.pcoded-header .dropdown .notification .noti-footer {
  border-top: 1px solid #f1f1f1;
  padding: 15px 20px;
  text-align: center;
  background: #ecf0f5;
}

.pcoded-header .dropdown .notification .noti-footer a {
  text-decoration: underline;
  font-size: 13px;
}

.pcoded-header .dropdown .notification ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.pcoded-header .dropdown .notification ul li {
  padding: 20px 15px;
}

.pcoded-header .dropdown .profile-notification {
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}

.pcoded-header .dropdown .profile-notification .pro-head {
  color: #fff;
  padding: 15px;
  position: relative;
  background: #101b33;
}

.pcoded-header .dropdown .profile-notification .pro-head img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.pcoded-header .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pcoded-header .dropdown .profile-notification .pro-body {
  padding: 20px 0;
  margin-bottom: 0;
  list-style: none;
}

.pcoded-header .dropdown .profile-notification .pro-body li {
  margin: 0 10px;
  border-radius: 2px;
}

.pcoded-header .dropdown .profile-notification .pro-body li a {
  color: #373a3c;
  font-size: 14px;
  padding: 10px;
}

.pcoded-header .dropdown .profile-notification .pro-body li a i {
  margin-right: 10px;
}

.pcoded-header .dropdown .profile-notification .pro-body li.active,
.pcoded-header .dropdown .profile-notification .pro-body li:active,
.pcoded-header .dropdown .profile-notification .pro-body li:focus,
.pcoded-header .dropdown .profile-notification .pro-body li:hover {
  background: #4680ff;
  box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);
}

.pcoded-header .dropdown .profile-notification .pro-body li.active > a,
.pcoded-header .dropdown .profile-notification .pro-body li:active > a,
.pcoded-header .dropdown .profile-notification .pro-body li:focus > a,
.pcoded-header .dropdown .profile-notification .pro-body li:hover > a {
  color: #fff;
  background: transparent;
}

/* header css end */
/* menu[ vartical ] css start */
.mob-toggler,
.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 10px;
}

.mob-toggler span,
.mobile-menu span {
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  background-color: rgba(16, 27, 51, 0.8);
  transition: 0.3s cubic-bezier(0.97, 0.75, 0.61, 1.84);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 2px;
}

.mob-toggler span:after,
.mob-toggler span:before,
.mobile-menu span:after,
.mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  background-color: rgba(16, 27, 51, 0.8);
  transition: 0.3s cubic-bezier(0.97, 0.75, 0.61, 1.84);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 2px;
}

.mob-toggler span:after,
.mobile-menu span:after {
  top: 6px;
  width: 70%;
}

.mob-toggler span:before,
.mobile-menu span:before {
  top: -6px;
  width: 40%;
}

.mob-toggler:hover span,
.mobile-menu:hover span {
  background-color: #101b33;
}

.mob-toggler:hover span:after,
.mob-toggler:hover span:before,
.mobile-menu:hover span:after,
.mobile-menu:hover span:before {
  background-color: #101b33;
  width: 100%;
}

.mob-toggler {
  right: 5px;
  display: none;
}

.pcoded-header:before,
.pcoded-main-container:before {
  content: "";
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar {
  display: block;
  z-index: 1028;
  position: absolute;
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
  transition: all 0.3s ease-in-out;
  width: 230px;
  height: calc(100% - 50px);
  margin-top: 50px;
  border-radius: 0 6px 0 0;
  top: 0;
  background: #101b33;
  color: #97a7c1;
}

.pcoded-navbar ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.pcoded-navbar .scroll-div.navbar-content {
  height: 100%;
}

.pcoded-navbar .header-logo {
  position: relative;
  align-items: center;
  display: inline-flex;
  float: left;
  background: #101b33;
  height: 50px;
  text-align: center;
  width: 230px;
  margin-right: 0;
  padding: 10px 25px;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar .header-logo .logo-dark {
  display: none;
}

.pcoded-navbar .header-logo .logo-thumb {
  transform: rotateY(-90deg);
  opacity: 0;
  position: absolute;
  transition: unset;
}

.pcoded-navbar .header-logo + .scroll-div {
  float: left;
  display: inline-block;
}

.pcoded-navbar .mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 0;
  padding: 0 10px;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar .pcoded-badge {
  font-size: 75%;
  position: relative;
  right: 0;
  top: -1px;
  padding: 2px 7px;
  border-radius: 2px;
  display: inline-block;
  margin-left: 10px;
}

.pcoded-navbar .main-menu-header {
  position: relative;
  padding: 25px 25px 10px;
  height: 116px;
  text-align: center;
}

.pcoded-navbar .main-menu-header .user-details {
  cursor: pointer;
}

.pcoded-navbar .main-menu-header .user-details > div {
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar .main-menu-header .img-radius {
  width: 60px;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar .main-menu-header + div .list-group-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 0.75rem 1.85rem;
  border: none;
}

.pcoded-navbar .pcoded-inner-navbar {
  flex-direction: column;
}

.pcoded-navbar .pcoded-inner-navbar li {
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li > a {
  text-align: left;
  padding: 7px 10px;
  margin: 5px 10px;
  border-radius: 0.25rem;
  display: block;
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li > a .pcoded-mtext {
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon {
  font-size: 16px;
  padding: 0;
  margin-right: 5px;
  border-radius: 4px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  text-align: center;
  justify-content: center;
}

.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon + .pcoded-mtext {
  position: relative;
  top: 0;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
  content: "\e844";
  font-family: "feather";
  font-size: 13px;
  border: none;
  position: absolute;
  top: 13px;
  right: 20px;
  transition: 0.3s ease-in-out;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger > a:after {
  transform: rotate(90deg);
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}

/* .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li + li a {
    border-top: 1px solid rgba(151, 167, 193, 0.2);
} */

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu a:after {
  top: 7px;
}
.pcoded-navbar
  .pcoded-inner-navbar
  li.pcoded-hasmenu.pcoded-trigger.active
  > .pcoded-submenu
  > .pcoded-hasmenu
  .active
  > .pcoded-submenu,
.pcoded-navbar
  .pcoded-inner-navbar
  li.pcoded-hasmenu.pcoded-trigger.active
  > .pcoded-submenu {
  display: block;
  background-color: transparent;
}

.pcoded-navbar
  .pcoded-inner-navbar
  li.pcoded-hasmenu.pcoded-trigger.active
  > a {
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li {
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
  text-align: left;
  padding: 10px 7px 10px 15px;
  display: block;
}

.pcoded-navbar
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li
  .pcoded-submenu:after {
  left: 45px;
  top: -20px;
  height: calc(100% - 15px);
}

.pcoded-navbar
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li
  .pcoded-submenu
  > li:after {
  left: 46px;
  width: 20px;
}

.pcoded-navbar
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li
  .pcoded-submenu
  > li
  > a {
  padding: 10px 7px 10px 65px;
}

.pcoded-navbar
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li
  .pcoded-submenu
  > li
  > a:before {
  left: 45px;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption {
  font-size: 13px;
  font-weight: 600;
  color: #4680ff;
  padding: 18px 15px 5px;
  text-transform: capitalize;
  position: relative;
  margin-top: 10px;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption:first-child {
  border-top: none;
  margin-top: 0;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption > label {
  margin-bottom: 0;
}

.pcoded-navbar .pcoded-inner-navbar li.disabled > a {
  cursor: default;
  opacity: 0.5;
}

.pcoded-navbar .pcoded-submenu {
  background: #101b33;
  padding: 15px 0;
}

.pcoded-navbar a {
  color: #97a7c1;
}

.pcoded-navbar .navbar-content,
.pcoded-navbar .navbar-wrapper {
  width: 100%;
  height: 100%;
}

.pcoded-navbar.navbar-collapsed {
  width: 70px;
  height: calc(100% - 50px);
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar.navbar-collapsed .header-logo {
  width: 70px;
  padding: 10px 20px;
}

.pcoded-navbar.navbar-collapsed .header-logo img {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: unset;
}

.pcoded-navbar.navbar-collapsed .header-logo .logo-thumb {
  transform: rotateY(0deg);
  transform-origin: 0 0;
  opacity: 1;
  left: calc((70px / 2) - 20px);
}

.pcoded-navbar.navbar-collapsed .header-logo .mobile-menu {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
}

.pcoded-navbar.navbar-collapsed .navbar-content.ps {
  overflow: visible;
}

.pcoded-navbar.navbar-collapsed .pcoded-menu-caption {
  position: relative;
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
}

.pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar.navbar-collapsed .pcoded-menu-caption:after {
  content: "";
  position: absolute;
  top: 32px;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: rgba(151, 167, 193, 0.5);
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a {
  padding: 7px 20px;
}

.pcoded-navbar.navbar-collapsed
  .pcoded-inner-navbar
  > li
  > a
  > .pcoded-micon
  + .pcoded-mtext {
  position: absolute;
  top: 11px;
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-mtext {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar.navbar-collapsed
  .pcoded-inner-navbar
  > li.pcoded-hasmenu
  > a:after {
  right: 12px;
}

.pcoded-navbar.navbar-collapsed
  .pcoded-inner-navbar
  > li.pcoded-hasmenu
  .pcoded-submenu {
  display: none;
}

.pcoded-navbar.navbar-collapsed
  .pcoded-inner-navbar
  > li.pcoded-trigger
  .pcoded-submenu
  li
  a {
  color: transparent;
  white-space: nowrap;
}

.pcoded-navbar.navbar-collapsed
  .pcoded-inner-navbar
  > li.pcoded-trigger
  .pcoded-submenu
  li
  + li
  a {
  border-top: none;
}

.pcoded-navbar.navbar-collapsed
  .pcoded-inner-navbar
  > li.pcoded-trigger
  .pcoded-submenu
  .pcoded-submenu
  li
  a:before {
  opacity: 0;
}

.pcoded-navbar.navbar-collapsed ~ .pcoded-main-container {
  margin-left: 70px;
}

.pcoded-navbar.navbar-collapsed .pcoded-badge {
  transform: rotateX(-90deg);
  transform-origin: 50% 50%;
  opacity: 0;
  display: none;
  transition: all 0.15s ease-in-out;
}

.pcoded-navbar.navbar-collapsed:hover {
  width: 230px !important;
}

.pcoded-navbar.navbar-collapsed:hover .header-logo {
  width: 230px;
}

.pcoded-navbar.navbar-collapsed:hover .header-logo img {
  transform: rotateY(0deg);
  opacity: 1;
}

.pcoded-navbar.navbar-collapsed:hover .header-logo .logo-thumb {
  transform: rotateY(-90deg);
  opacity: 0;
}

.pcoded-navbar.navbar-collapsed:hover .header-logo .mobile-menu {
  display: flex;
  right: 10px;
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption > label {
  transform: rotateY(0deg);
  opacity: 1;
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption:after {
  background: transparent;
}

.pcoded-navbar.navbar-collapsed:hover .mobile-menu {
  transform: rotateY(0deg);
  opacity: 1;
}

.pcoded-navbar.navbar-collapsed:hover
  .pcoded-inner-navbar
  > li
  > a
  > .pcoded-mtext {
  transform: rotateY(0deg);
  opacity: 1;
}

.pcoded-navbar.navbar-collapsed:hover
  .pcoded-inner-navbar
  > li
  > a
  > .pcoded-mtext:after {
  opacity: 1;
  visibility: visible;
}

.pcoded-navbar.navbar-collapsed:hover
  .pcoded-inner-navbar
  > li.pcoded-hasmenu
  > a:after {
  transform: rotateX(0deg);
}

.pcoded-navbar.navbar-collapsed:hover
  .pcoded-inner-navbar
  > li.pcoded-hasmenu.pcoded-trigger
  > a:after {
  transform: rotateX(0deg) rotate(90deg);
}

.pcoded-navbar.navbar-collapsed:hover
  .pcoded-inner-navbar
  > li.pcoded-trigger
  .pcoded-submenu
  li
  a {
  color: inherit;
}

.pcoded-navbar.navbar-collapsed:hover
  .pcoded-inner-navbar
  > li.pcoded-trigger
  .pcoded-submenu
  .pcoded-submenu
  li
  a:before {
  opacity: 1;
}

.pcoded-navbar.navbar-collapsed:not(:hover) .pcoded-inner-navbar > li > a {
  padding: 7px 10px;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li
  > a:after {
  display: none;
}

.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.active
  > a,
.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger
  > a {
  width: calc(70px - 20px);
}
.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger.pcoded-hasmenu
  > .pcoded-submenu
  li.active {
  box-shadow: none;
  border-bottom: none;
}

.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger.pcoded-hasmenu
  > .pcoded-submenu
  li.active
  > a:before,
.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger.pcoded-hasmenu
  > .pcoded-submenu
  li.pcoded-trigger
  > a:before,
.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger.pcoded-hasmenu
  > .pcoded-submenu
  li:hover
  > a:before {
  content: "";
  position: absolute;
  top: 10px;
  left: -14px;
  border-radius: 50%;
  background: #4680ff;
  padding: 5px;
  box-shadow: 0 0 0 5px #fff;
  z-index: 6;
}
.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger.pcoded-hasmenu
  > .pcoded-submenu
  li.active
  > .toggle-list {
  display: none;
}
.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > .no-hasmenu
  > .toggle-list {
  display: none;
}

.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger.pcoded-hasmenu
  > .pcoded-submenu
  li.active
  > a,
.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger.pcoded-hasmenu
  > .pcoded-submenu
  li.pcoded-trigger
  > a,
.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger.pcoded-hasmenu
  > .pcoded-submenu
  li:hover
  > a {
  box-shadow: none;
  border: none;
}

.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger.pcoded-hasmenu
  > .pcoded-submenu:before {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(70px / 2);
  z-index: 5;
  background: rgba(151, 167, 193, 0.2);
}

.pcoded-navbar.navbar-collapsed:not(:hover)
  .pcoded-inner-navbar
  > li.pcoded-trigger
  .pcoded-submenu
  li
  + li
  > a {
  border-top-color: transparent !important;
}

.pcoded-navbar.navbar-collapsed:not(:hover) .main-menu-header {
  padding: 25px 9px;
}

.pcoded-navbar.navbar-collapsed:not(:hover) .main-menu-header .img-radius {
  width: calc(70px - 20px);
}

.pcoded-navbar.navbar-collapsed:not(:hover)
  .main-menu-header
  .user-details
  > div {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
}

.pcoded-navbar .pcoded-menu-caption {
  color: #8090a5;
}

.pcoded-navbar .pcoded-inner-navbar li.active > a,
.pcoded-navbar .pcoded-inner-navbar li:focus > a,
.pcoded-navbar .pcoded-inner-navbar li:hover > a {
  color: #e5efff;
}

.pcoded-navbar .pcoded-inner-navbar > li.active > a,
.pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #4680ff;
  color: #fff;
  box-shadow: 0 10px 5px -8px rgba(0, 0, 0, 0.4);
}

.pcoded-navbar .pcoded-inner-navbar > .pcoded-menu-caption.active:after,
.pcoded-navbar
  .pcoded-inner-navbar
  > .pcoded-menu-caption.pcoded-trigger:after {
  display: none;
}

.pcoded-navbar.mob-open ~ .pcoded-header:before,
.pcoded-navbar.mob-open ~ .pcoded-main-container:before,
.pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header:before,
.pcoded-navbar.navbar-collapsed:hover ~ .pcoded-main-container:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* ===  scroll bar start  ===== */
.navbar-content {
  position: relative;
}

.ps__rail-y {
  z-index: 5;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent;
}

.ps__thumb-y {
  background: #98aec9;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background: #a9bbd2;
}

/* ===  scroll bar End  ===== */
/* menu [ vartical ] css end */
/* menu[ horizontal ] css start */
.pcoded-navbar.theme-horizontal {
  display: block;
  height: 50px;
  width: 100%;
  z-index: 1023;
  position: fixed;
  border-radius: 0;
  top: 50px;
  margin-top: 0;
}

.pcoded-navbar.theme-horizontal .pcoded-badge {
  display: none;
}

.pcoded-navbar.theme-horizontal.top-nav-collapse {
  top: 0;
}

.pcoded-navbar.theme-horizontal.default,
.pcoded-navbar.theme-horizontal.default.top-nav-collapse {
  top: 50px;
}

.pcoded-navbar.theme-horizontal.header-hide {
  top: 0;
}

.pcoded-navbar.theme-horizontal.header-hide.top-nav-collapse {
  top: -50px;
}

.pcoded-navbar.theme-horizontal.header-hide ~ .pcoded-main-container {
  margin-top: 50px;
}

.pcoded-navbar.theme-horizontal .header-logo {
  display: none;
}

.pcoded-navbar.theme-horizontal .sidenav-horizontal-wrapper {
  display: flex;
  align-items: center;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-menu-caption {
  display: none;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li {
  display: inline-flex;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a {
  margin: 0;
  padding: 8px 15px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a > .pcoded-mtext {
  position: relative;
  top: 0;
  margin-right: 5px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a:after {
  position: relative;
  top: 0;
  right: 0;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.active a,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger a,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li:hover a {
  box-shadow: none;
  color: #fff;
  background: transparent;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  > li.pcoded-hasmenu.active:before,
.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  > li.pcoded-hasmenu.pcoded-trigger:before {
  content: "\6d";
  position: absolute;
  bottom: -24px;
  left: 0;
  font-family: "pct";
  z-index: 1001;
  font-size: 50px;
  line-height: 1;
  padding-left: calc(50% - 25px);
  color: #101b33;
  text-shadow: 0 3px 4px rgba(69, 90, 100, 0.3);
  width: 100%;
  height: 40px;
  transform: scaleX(1.2);
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  > li.pcoded-hasmenu.active
  > .pcoded-submenu,
.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  > li.pcoded-hasmenu.pcoded-trigger
  > .pcoded-submenu {
  margin-top: 30px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.active:after,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger:after {
  content: "";
  background-color: #4680ff;
  z-index: 4;
  position: absolute;
  left: 19px;
  top: auto;
  bottom: 5px;
  width: 23px;
  height: 2px;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  > li.pcoded-hasmenu.edge
  > .pcoded-submenu {
  left: auto;
  right: 0;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu {
  position: relative;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu {
  opacity: 0;
  visibility: hidden;
  transform-origin: 50% 50%;
  transition: transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  transform: rotateX(-90deg);
  position: absolute;
  min-width: 250px;
  display: block;
  z-index: 1;
  top: 100%;
  list-style: outside none none;
  margin: 0;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  background: #fff;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu:after {
  display: none;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  a {
  color: #101b33;
  padding: 10px 20px 10px 30px;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  a:before {
  left: 5px;
  top: 11px;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  li
  + li
  a {
  border-top-color: #ededed;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  li:after {
  display: none;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  li.active
  > a,
.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  li.pcoded-trigger
  > a,
.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  li:hover
  > a {
  color: #4680ff;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  .pcoded-submenu {
  position: absolute;
  min-width: 250px;
  z-index: 1;
  left: calc(100% + 10px);
  top: -10px;
  margin: 0 0 0 20px;
  border-radius: 4px;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  .pcoded-submenu:before {
  content: "\6a";
  position: absolute;
  top: 8px;
  left: -31px;
  font-family: "pct";
  z-index: 1001;
  font-size: 50px;
  line-height: 1;
  color: #fff;
  width: 40px;
  height: 100%;
  text-shadow: -8px 0 13px rgba(62, 57, 107, 0.08);
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  .pcoded-submenu
  a {
  color: #101b33;
  padding: 10px 20px 10px 30px;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  .pcoded-submenu
  a:before {
  left: 5px;
  top: 11px;
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  .pcoded-hasmenu.edge
  .pcoded-submenu {
  left: auto;
  margin: 0 20px 0 0;
  right: calc(100% + 10px);
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu
  .pcoded-submenu
  .pcoded-hasmenu.edge
  .pcoded-submenu:before {
  content: "\6b";
  left: auto;
  right: -21px;
  text-shadow: 8px 0 13px rgba(62, 57, 107, 0.08);
}

.pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  .pcoded-hasmenu.pcoded-trigger
  > .pcoded-submenu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}

.pcoded-navbar.theme-horizontal .navbar-content {
  display: flex;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header {
  position: fixed;
  top: 0;
  margin-left: 0;
  width: 100%;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header:not(.headerpos-fixed) {
  background: #4680ff;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header .b-title {
  color: #101b33;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .b-title {
  color: #fff;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header {
  display: inline-flex;
  padding: 0 15px;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header .logo-main,
.pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header .logo-thumb {
  display: none;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header .logo-dark {
  display: inline-block;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header .mobile-menu {
  display: none;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .m-header {
  display: inline-flex;
  padding: 0 15px;
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .m-header
  .logo-dark,
.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .m-header
  .logo-thumb {
  display: none;
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .m-header
  .logo-main {
  display: inline-block;
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu {
  color: #373a3c;
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  a {
  color: #373a3c;
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  > li
  > a {
  color: #373a3c;
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  > li.active,
.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  > li:active,
.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  > li:focus,
.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  > li:hover {
  background: rgba(70, 128, 255, 0.1);
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  > li.active
  > a,
.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  > li:active
  > a,
.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  > li:focus
  > a,
.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown-menu
  > li:hover
  > a {
  background: transparent;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] a,
.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown
  .notification
  .noti-body
  li.notification:hover {
  background: rgba(70, 128, 255, 0.1);
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown
  .profile-notification
  .pro-head {
  color: #fff;
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-header[class*="header-"]
  .dropdown
  .profile-notification
  .pro-head
  .dud-logout {
  color: #fff;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-main-container {
  margin-top: 100px;
  margin-left: 0;
  padding-top: 1px;
  min-height: calc(100vh - 100px);
}

.pcoded-navbar.theme-horizontal ~ .pcoded-main-container .page-header h5 {
  color: #37474f;
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-main-container
  .page-header
  .page-header-title
  + .breadcrumb
  > .breadcrumb-item:before {
  color: rgba(55, 71, 79, 0.8);
}

.pcoded-navbar.theme-horizontal
  ~ .pcoded-main-container
  .page-header
  .page-header-title
  + .breadcrumb
  > .breadcrumb-item
  a {
  color: #37474f;
}

/* menu [ horizontal ] css end */
/* main content start */
.pcoded-content {
  position: relative;
  display: block;
  padding: 25px;
  margin-top: 140px;
}

.pcoded-main-container {
  background: #ecf0f5;
  position: relative;
  margin: 0 auto;
  padding-top: 0;
  display: block;
  clear: both;
  float: unset;
  right: 0;
  top: 0;
  margin-left: 230px;
  min-height: 100vh;
  height: auto;
  transition: all 0.3s ease-in-out;
}

/* main content end*/
/* ==========================    Rsponsive Menu  start   ====================== */
@media only screen and (max-width: 991px) {
  .pcoded-header .input-group-text,
  .pcoded-header a,
  .pcoded-header dropdown-toggle {
    color: #101b33;
  }

  .pcoded-header .input-group-text:hover,
  .pcoded-header a:hover,
  .pcoded-header dropdown-toggle:hover {
    color: #4680ff;
  }

  .pcoded-header #mobile-header {
    display: none;
    right: 20px;
  }

  .pcoded-header .container > .collapse:not(.show),
  .pcoded-header > .collapse:not(.show) {
    background: #fff;
    box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
    position: relative;
  }

  .pcoded-header .container > .collapse:not(.show) .mob-toggler:after,
  .pcoded-header > .collapse:not(.show) .mob-toggler:after {
    content: "\e89a";
    font-family: "feather";
    font-size: 20px;
    color: #101b33;
    position: absolute;
    right: 10px;
    top: 0;
    width: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pcoded-header .container > .collapse:not(.show) .mr-auto,
  .pcoded-header > .collapse:not(.show) .mr-auto {
    height: 50px;
    display: flex;
    justify-content: center;
  }

  .pcoded-header .container > .collapse:not(.show) .ml-auto,
  .pcoded-header > .collapse:not(.show) .ml-auto {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .pcoded-header .dropdown-menu,
  .pcoded-header .dropdown-menu a,
  .pcoded-header .search-close .input-group-text {
    color: #101b33;
  }

  .pcoded-header .m-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
  }

  .pcoded-header .m-header .mobile-menu {
    right: auto;
    left: 10px;
  }

  .pcoded-header .m-header .mob-toggler {
    display: flex;
  }

  .pcoded-header .full-screen {
    display: none;
  }

  .pcoded-header .navbar-nav > li:last-child {
    padding-right: 20px;
  }

  .pcoded-navbar {
    margin-left: -230px;
    position: absolute;
    height: calc(100% - 50px);
  }

  .pcoded-navbar .scroll-div.navbar-content {
    height: 100%;
  }

  .pcoded-navbar ~ .pcoded-header,
  .pcoded-navbar ~ .pcoded-main-container {
    margin-left: 0;
  }

  .pcoded-navbar ~ .pcoded-header {
    width: 100%;
  }

  .pcoded-navbar .navbar-brand {
    display: none;
  }

  .pcoded-navbar.mob-open {
    margin-left: 0;
  }

  .pcoded-navbar.mob-open .pcoded-main-container ~ .pcoded-header,
  .pcoded-navbar.mob-open ~ .pcoded-main-container {
    margin-left: 0;
  }

  .pcoded-main-container {
    padding-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .pcoded-header .main-search.open .input-group .search-close {
    display: none;
  }

  .pcoded-header .navbar-nav > li {
    padding: 0 8px;
  }

  .pcoded-header .navbar-nav.ml-auto > li:first-child {
    padding-left: 0;
  }

  .pcoded-header .navbar-nav > li:last-child {
    padding-right: 15px;
  }

  .pcoded-header .navbar-nav.mr-auto li.nav-item:not(.dropdown) {
    display: block;
    padding-right: 0;
  }

  .pcoded-header .navbar-nav.mr-auto li.nav-item:not(.dropdown) .search-close {
    display: none;
  }

  .pcoded-header .dropdown.show {
    position: static;
  }

  .pcoded-header .dropdown.show a:after {
    display: none;
  }

  .pcoded-header .dropdown.show:before {
    display: none;
  }

  .pcoded-header .dropdown .notification {
    width: 100%;
  }

  .pcoded-header .dropdown .dropdown-menu {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .header-chat.open,
  .header-user-list.open {
    width: 280px;
  }

  .pcoded-content {
    padding: 25px 15px;
  }

  .card {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .pcoded-navbar .header-logo {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed .header-logo img {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed
    .pcoded-inner-navbar
    > li
    > a
    > .pcoded-mtext {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed
    .pcoded-inner-navbar
    > li.pcoded-hasmenu
    > a:after {
    transition: none;
    transition-delay: 0;
  }

  .pcoded-navbar.navbar-collapsed .pcoded-badge {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed:hover .pcoded-badge {
    transition-delay: 0;
  }
}

/* responsive horizontal menu */
@media only screen and (max-width: 991px) {
  .pcoded-navbar.theme-horizontal {
    margin-left: 0;
  }

  .pcoded-navbar.theme-horizontal
    ~ .pcoded-header
    .container
    > .collapse:not(.show),
  .pcoded-navbar.theme-horizontal ~ .pcoded-header > .collapse:not(.show) {
    display: inline-flex;
    background: #fff;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  }

  .pcoded-navbar.theme-horizontal ~ .pcoded-header .b-title {
    color: #fff;
  }

  .pcoded-navbar.theme-horizontal
    .pcoded-inner-navbar
    .pcoded-hasmenu.pcoded-trigger
    > .pcoded-submenu
    .pcoded-trigger
    > .pcoded-submenu,
  .pcoded-navbar.theme-horizontal
    .pcoded-inner-navbar
    .pcoded-hasmenu.pcoded-trigger
    > .pcoded-submenu
    .pcoded-trigger.edge
    > .pcoded-submenu {
    position: relative;
    left: 0;
    min-width: 100%;
    margin: 0;
    box-shadow: none;
  }

  .pcoded-navbar.theme-horizontal
    .pcoded-inner-navbar
    .pcoded-hasmenu.pcoded-trigger
    > .pcoded-submenu
    .pcoded-trigger
    > .pcoded-submenu:before,
  .pcoded-navbar.theme-horizontal
    .pcoded-inner-navbar
    .pcoded-hasmenu.pcoded-trigger
    > .pcoded-submenu
    .pcoded-trigger.edge
    > .pcoded-submenu:before {
    display: none;
  }
}

/* ==========================    Rsponsive Menu  end   ====================== */
/* ******************************************************************************* */
/* * Horizontal */
.theme-horizontal .sidenav-horizontal-wrapper {
  flex: 1 1 auto;
  width: 0;
}

.sidenav:not(.sidenav-no-animation)
  .theme-horizontal
  .sidenav-horizontal-wrapper
  .sidenav-inner {
  transition: margin 0.2s;
}

.theme-horizontal .sidenav-horizontal-next,
.theme-horizontal .sidenav-horizontal-prev {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 2.25rem;
  background-color: #101b33;
  z-index: 9;
}

.theme-horizontal .sidenav-horizontal-next::after,
.theme-horizontal .sidenav-horizontal-prev::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-top: 0;
}

.theme-horizontal .sidenav-horizontal-next.disabled,
.theme-horizontal .sidenav-horizontal-prev.disabled {
  cursor: default !important;
  opacity: 0;
}

.theme-horizontal .sidenav-horizontal-prev::after {
  border-right: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.theme-horizontal .sidenav-horizontal-next::after {
  border-left: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.theme-horizontal .sidenav-horizontal:after,
.theme-horizontal .sidenav-horizontal:before {
  content: "";
  background: #101b33;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 5;
}

@media only screen and (max-width: 991px) {
  .theme-horizontal .sidenav-horizontal:after,
  .theme-horizontal .sidenav-horizontal:before {
    display: none;
  }
}

.theme-horizontal .sidenav-horizontal:before {
  left: 100%;
}

.theme-horizontal .sidenav-horizontal:after {
  right: 100%;
}

.theme-horizontal.menu-light .sidenav-horizontal:after,
.theme-horizontal.menu-light .sidenav-horizontal:before {
  background: #fff;
}

@-webkit-keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.pcoded-navbar .card {
  background: rgba(255, 255, 255, 0.1);
  margin: 20px;
  box-shadow: 0 0 0 1px rgba(226, 229, 232, 0.15);
  color: rgba(255, 255, 255, 0.6);
}

.pcoded-navbar .card h6 {
  color: rgba(255, 255, 255, 0.6);
}

.pcoded-navbar .card .close {
  position: absolute;
  color: #97a7c1;
  position: absolute;
  top: 10px;
  right: 12px;
  opacity: 0.8;
  text-shadow: none;
}

.pcoded-navbar.menu-light .card {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px #ecf0f5;
  color: rgba(0, 0, 0, 0.6);
}

.pcoded-navbar.menu-light .card h6 {
  color: #535763;
}

.pcoded-navbar.menu-light .card .close {
  color: #535763;
}

.pcoded-navbar.navbar-collapsed .card {
  display: none;
}

/* ===================================================================================
 ==========================    Diffrent Layout Styles Start     ====================
 ===================================================================================
 ====== [ light menu style start ] ======== */
.pcoded-navbar.menu-light {
  background-color: #fff;
  color: #535763;
}

.pcoded-navbar.menu-light .header-logo {
  background-color: #101b33;
}

.pcoded-navbar.menu-light .header-logo .logo-dark {
  display: none;
}

.pcoded-navbar.menu-light .header-logo .logo-main {
  display: block;
}

.pcoded-navbar.menu-light .sidenav-horizontal-next,
.pcoded-navbar.menu-light .sidenav-horizontal-prev {
  background: #fff;
}

.pcoded-navbar.menu-light .mobile-menu span {
  background-color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.menu-light .mobile-menu span:after,
.pcoded-navbar.menu-light .mobile-menu span:before {
  background-color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.menu-light .mobile-menu.on span {
  background-color: transparent;
}

/* .pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li + li a {
    border-top: 1px solid rgba(151, 167, 193, 0.5);
} */

.pcoded-navbar.menu-light
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li.active
  > a:before,
.pcoded-navbar.menu-light
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li.pcoded-trigger
  > a:before,
.pcoded-navbar.menu-light
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li:hover
  > a:before {
  color: #4680ff;
}

.pcoded-navbar.menu-light
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li
  + li
  > a {
  border-top-color: #ededed;
}

.pcoded-navbar.menu-light .pcoded-submenu {
  background: transparent;
}

.pcoded-navbar.menu-light a {
  color: #535763;
}

.pcoded-navbar.menu-light.navbar-collapsed .pcoded-menu-caption:after {
  background: rgba(0, 0, 0, 0.1);
}

.pcoded-navbar.menu-light.navbar-collapsed:hover .pcoded-menu-caption:after {
  background: transparent;
}

.pcoded-navbar.menu-light .pcoded-menu-caption {
  color: #535763;
}

.pcoded-navbar.menu-light .pcoded-inner-navbar li.active > a,
.pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-trigger > a,
.pcoded-navbar.menu-light .pcoded-inner-navbar li:hover > a {
  color: #4680ff;
  background: transparent;
}
.pcoded-navbar.menu-light .pcoded-inner-navbar .pcoded-submenu li {
  width: 80%;
  margin-left: 2.2rem;
}

.pcoded-navbar.menu-light .pcoded-inner-navbar .pcoded-submenu li.active {
  border-radius: 7px;
  border-bottom: 1px solid #4680fe;
  box-shadow: 1px 8px 16.1px 0px rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.menu-light .pcoded-inner-navbar li:not(.active):hover > a {
  border-radius: 7px;
  border-bottom: 1px solid #4680fe;
  box-shadow: 1px 8px 16.1px 0px rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.menu-light .pcoded-inner-navbar > li.active > a,
.pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #4680ff;
  color: #fff;
}

.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.active a,
.pcoded-navbar.menu-light.theme-horizontal
  .pcoded-inner-navbar
  > li.pcoded-trigger
  a,
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li:hover a {
  color: #535763;
  background: transparent;
}

.pcoded-navbar.menu-light.theme-horizontal
  .pcoded-inner-navbar
  > li.pcoded-hasmenu.active:before,
.pcoded-navbar.menu-light.theme-horizontal
  .pcoded-inner-navbar
  > li.pcoded-hasmenu.pcoded-trigger:before {
  color: #fff;
}

/* ====== [ light menu style end ] ======== */
/* ====== [ Header color start ] ======== */
.pcoded-header[class*="header-"] .mobile-menu span {
  background-color: rgba(255, 255, 255, 0.8);
}

.pcoded-header[class*="header-"] .mobile-menu span:after,
.pcoded-header[class*="header-"] .mobile-menu span:before {
  background-color: rgba(255, 255, 255, 0.8);
}

.pcoded-header[class*="header-"] .mobile-menu:hover span {
  background-color: #fff;
}

.pcoded-header[class*="header-"] .mobile-menu:hover span:after,
.pcoded-header[class*="header-"] .mobile-menu:hover span:before {
  background-color: #fff;
}

@media only screen and (min-width: 992px) {
  .pcoded-header[class*="header-"]
    .page-header
    .breadcrumb-item
    + .breadcrumb-item::before,
  .pcoded-header[class*="header-"]
    .page-header
    .page-header-title
    + .breadcrumb
    > .breadcrumb-item
    a,
  .pcoded-header[class*="header-"] .page-header h5 {
    color: #fff;
  }
}

.pcoded-header.header-blue {
  background: #4680ff;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-blue:not(.headerpos-fixed) {
  background: transparent;
}

.pcoded-header.header-blue .profile-notification li > a {
  color: #373a3c;
}

.pcoded-header.header-blue .profile-notification li.active,
.pcoded-header.header-blue .profile-notification li:active,
.pcoded-header.header-blue .profile-notification li:focus,
.pcoded-header.header-blue .profile-notification li:hover {
  background: rgba(70, 128, 255, 0.1);
}

.pcoded-header.header-blue .profile-notification li.active > a,
.pcoded-header.header-blue .profile-notification li:active > a,
.pcoded-header.header-blue .profile-notification li:focus > a,
.pcoded-header.header-blue .profile-notification li:hover > a {
  background: transparent;
}

.pcoded-header.header-blue .dropdown-menu {
  color: #373a3c;
}

.pcoded-header.header-blue .dropdown-menu a {
  color: #373a3c;
}

.pcoded-header.header-blue .dropdown-menu > li > a {
  color: #373a3c;
}

.pcoded-header.header-blue .dropdown-menu > li.active,
.pcoded-header.header-blue .dropdown-menu > li:active,
.pcoded-header.header-blue .dropdown-menu > li:focus,
.pcoded-header.header-blue .dropdown-menu > li:hover {
  background: rgba(70, 128, 255, 0.1);
  color: #373a3c;
}

.pcoded-header.header-blue .dropdown-menu > li.active > a,
.pcoded-header.header-blue .dropdown-menu > li:active > a,
.pcoded-header.header-blue .dropdown-menu > li:focus > a,
.pcoded-header.header-blue .dropdown-menu > li:hover > a {
  background: transparent;
}

.pcoded-header.header-blue .input-group .input-group-text,
.pcoded-header.header-blue a,
.pcoded-header.header-blue dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-blue .input-group .input-group-text:hover,
.pcoded-header.header-blue a:hover,
.pcoded-header.header-blue dropdown-toggle:hover {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .pcoded-header.header-blue .input-group .input-group-text,
  .pcoded-header.header-blue a,
  .pcoded-header.header-blue dropdown-toggle {
    color: #535763;
  }

  .pcoded-header.header-blue .input-group .input-group-text:hover,
  .pcoded-header.header-blue a:hover,
  .pcoded-header.header-blue dropdown-toggle:hover {
    color: #3c3f47;
  }
}

@media only screen and (max-width: 991px) {
  .pcoded-header.header-blue .m-header > a {
    color: #fff;
  }
}

.pcoded-header.header-blue .main-search .search-close > .input-group-text {
  color: #4680ff;
}

.pcoded-header.header-blue .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}

@media only screen and (max-width: 991px) {
  .pcoded-header.header-blue .main-search.open .input-group {
    background: #ecf0f5;
  }
}

@media only screen and (min-width: 992px) {
  .pcoded-header.header-blue .main-search.open .input-group .form-control {
    color: #fff;
  }

  .pcoded-header.header-blue
    .main-search.open
    .input-group
    .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  .pcoded-header.header-blue
    .main-search.open
    .input-group
    .form-control:-ms-input-placeholder {
    color: #fff;
  }

  .pcoded-header.header-blue
    .main-search.open
    .input-group
    .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}

.pcoded-header.header-blue .main-search.open .input-group .search-btn {
  background: #4680ff;
  border-color: #4680ff;
}

.pcoded-header.header-blue
  .main-search.open
  .input-group
  .search-btn
  .input-group-text {
  color: #fff;
}

.pcoded-header.header-blue .dropdown .notification .noti-head a {
  color: #fff;
}

.pcoded-header.header-blue
  .dropdown
  .notification
  .noti-body
  li.notification:hover {
  background: rgba(70, 128, 255, 0.1);
}

.pcoded-header.header-blue .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #4680ff;
}

.pcoded-header.header-blue
  .dropdown
  .profile-notification
  .pro-head
  .dud-logout {
  color: #fff;
}

.pcoded-header.header-blue .b-bg {
  background: #fff;
  color: #4680ff;
}

/* ====== [ Header color end ] ======== */
.dropdown-menu {
  transform-origin: 0 0;
  opacity: 0;
  top: 100% !important;
  transform: scale(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.dropdown-menu.dropdown-menu-right {
  transform-origin: 100% 0;
  right: 0;
  left: auto !important;
}

.dropdown-menu[x-placement="top-start"] {
  transform-origin: 0 100%;
  bottom: 100% !important;
  top: auto !important;
}

.dropdown-menu.show,
.open > .dropdown-menu {
  display: block;
  opacity: 1;
  -webkit-animation-name: bmd-dropdown-animation;
  animation-name: bmd-dropdown-animation;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@-webkit-keyframes bmd-dropdown-animation {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bmd-dropdown-animation {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: background 0s ease-out;
}

.form-control:focus {
  border-bottom-color: transparent;
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
  background-image: linear-gradient(
      to top,
      #4680ff 2px,
      rgba(70, 128, 255, 0) 2px
    ),
    linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px);
}

.form-group {
  position: relative;
}

.form-group .floating-label {
  position: absolute;
  top: 11px;
  left: 0;
  font-size: 0.875rem;
  z-index: 1;
  cursor: text;
  transition: all 0.3s ease;
}

.form-group .floating-label + .form-control {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}

.form-group.fill .floating-label {
  top: -10px;
  font-size: 0.75rem;
  color: #4680ff;
}

.spinner-border {
  border-bottom-width: 0;
  border-left-width: 0;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs .nav-link {
  border: none;
  background: no-repeat center bottom, center 100%;
  background-size: 0 100%, 100% 100%;
  transition: background 0.3s ease-out;
  background-image: linear-gradient(
    to top,
    #4680ff 2px,
    rgba(255, 255, 255, 0) 2px
  );
}

.nav-tabs .nav-link.active {
  background-size: 100% 100%, 100% 100%;
}

.page-item .page-link {
  border-radius: 60px;
  margin: 0 5px;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #4680ff;
  border-color: #4680ff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

.has-ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple {
  display: block;
  position: absolute;
  pointer-events: none;
  border-radius: 50%;
  transform: scale(0);
  background: #fff;
  opacity: 1;
}

.ripple-animate {
  -webkit-animation: ripple;
  animation: ripple;
}

@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.fixed-button {
  position: fixed;
  bottom: -50px;
  right: 30px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  opacity: 0;
  z-index: 9;
  transition: all 0.5s ease;
}

.fixed-button.active {
  bottom: 50px;
  opacity: 1;
}

.social-card {
  color: #fff;
  overflow: hidden;
}

.social-card .social-icon {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 22px 23px;
}

.social-card .download-icon {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  height: 125px;
  width: 125px;
  right: -125px;
  font-size: 35px;
  padding: 40px 22px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.social-card:hover .download-icon {
  right: -60px;
}

.total-card {
  overflow: hidden;
}

.total-card .text-left h4 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 5px;
}

.total-card .text-left p {
  color: #fff;
  font-size: 15px;
}

.table-card .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.table-card .card-body .table > thead > tr > th {
  border-top: 0;
}

.table-card .card-body .table .chk-option {
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}

.table-card .card-body .table .chk-option .checkbox-fade,
.table-card .card-body .table .chk-option .checkbox-fade .cr,
.table-card .card-body .table .chk-option .checkbox-zoom,
.table-card .card-body .table .chk-option .checkbox-zoom .cr {
  margin-right: 0;
}

.table-card .card-body .table label {
  margin-bottom: 0;
}

.table-card .card-body .table tr td:first-child,
.table-card .card-body .table tr th:first-child {
  padding-left: 20px;
}

.table-card .card-body .table tr td:last-child,
.table-card .card-body .table tr th:last-child {
  padding-right: 20px;
}

.latest-update-card .card-body {
  padding-top: 0;
}

.latest-update-card .card-body .latest-update-box {
  position: relative;
}

.latest-update-card .card-body .latest-update-box td {
  border-top: none;
}

.latest-update-card .card-body .latest-update-box:after {
  content: "";
  position: absolute;
  background: #e2e5e8;
  height: 100%;
  width: 1px;
  top: 0;
  left: 110px;
  z-index: 1;
}

.latest-update-card .card-body .latest-update-box .update-meta {
  z-index: 2;
  min-width: 160px;
}

.latest-update-card .card-body .latest-update-box .update-meta .update-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 12px 13px;
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
}

@media only screen and (max-width: 575px) {
  .latest-update-card .card-body .latest-update-box:after {
    display: none;
  }

  .latest-update-card .card-body .latest-update-box .update-meta {
    z-index: 2;
    min-width: 100%;
    text-align: left !important;
    margin-bottom: 15px;
    border-top: 1px solid #f1f1f1;
    padding-top: 15px;
  }
}

.review-card .review-block .cust-img {
  width: 50px;
  height: 50px;
}

.review-card .review-block > div {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.review-card .review-block > div:hover {
  background-color: rgba(70, 128, 255, 0.1);
}

.user-card2 .risk-rate {
  display: inline-block;
  margin: 0 auto;
}

.user-card2 .risk-rate span {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 6px dashed #d6d6d6;
  border-top-color: #9ccc65;
  border-bottom-color: transparent;
  padding: 45px;
  display: block;
  position: relative;
}

.user-card2 .risk-rate span:after {
  content: "";
  width: 90px;
  height: 90px;
  background-color: rgba(156, 204, 101, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
}

.user-card2 .risk-rate span b {
  font-size: 20px;
  color: #fff;
  z-index: 2;
  position: relative;
}

.to-do-list {
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.to-do-list i {
  color: #ccc;
  font-size: 17px;
  opacity: 0;
}

.to-do-list:last-child {
  border: none;
  padding: 0;
  margin: 0;
}

.to-do-list:hover i {
  opacity: 1;
  transition: opacity ease-in 0.3s;
}

.to-do-list p {
  display: inline-block;
}

.to-do-label .checkbox-fade {
  display: block;
}

.to-do-label .checkbox-fade .delete_todo {
  float: right;
  font-size: 24px;
  color: #ccc;
}

.to-do-label .check-task {
  display: block;
}

.to-do-label i {
  cursor: pointer;
}

.done-task .captions,
.done-task span {
  color: #919aa3;
  text-decoration: line-through;
}

.trafic-card .progress {
  height: 7px;
}

.trafic-card .progress.blue {
  background-color: rgba(70, 128, 255, 0.5);
}

.trafic-card .progress.green {
  background-color: rgba(156, 204, 101, 0.5);
}

.trafic-card .progress.red {
  background-color: rgba(255, 82, 82, 0.5);
}

.trafic-card .progress.purple {
  background-color: rgba(83, 109, 254, 0.5);
}

.trafic-card .progress.yellow {
  background-color: rgba(255, 186, 87, 0.5);
}

.user-activity-card .u-img {
  position: relative;
}

.user-activity-card .u-img .cover-img {
  width: 40px;
  height: 40px;
}

.user-activity-card .u-img .profile-img {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: -5px;
}

.user-Messages-card .u-img {
  position: relative;
}

.user-Messages-card .u-img .profile-img {
  width: 40px;
  height: 40px;
}

.user-Messages-card .u-img .tot-msg {
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0;
  right: -5px;
  background-color: #ff5252;
  color: #fff;
  font-size: 10px;
  border-radius: 50%;
  text-align: center;
}

.latest-posts-card .latest-posts-img {
  width: 90px;
  height: 80px;
}

.task-card .task-list {
  position: relative;
}

.task-card .task-list:after {
  content: "";
  position: absolute;
  background: #e2e5e8;
  height: 100%;
  width: 2px;
  top: 0;
  left: 10px;
  z-index: 1;
}

.task-card .task-list:before {
  content: "";
  position: absolute;
  background: #e2e5e8;
  height: 15px;
  width: 15px;
  bottom: -14px;
  left: 3px;
  z-index: 2;
  border-radius: 50%;
}

.task-card .task-list li {
  margin-bottom: 30px;
  padding-left: 30px;
  position: relative;
}

.task-card .task-list li .task-icon {
  position: absolute;
  left: 3px;
  top: 1px;
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  min-width: 15px;
  min-height: 15px;
  z-index: 2;
}

.latest-activity-card .card-body {
  padding-top: 0;
}

.latest-activity-card .card-body .latest-update-box {
  position: relative;
}

.latest-activity-card .card-body .latest-update-box .update-meta {
  z-index: 2;
  min-width: 160px;
}

.latest-activity-card .card-body .latest-update-box .update-meta .update-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 12px 13px;
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
}

@media only screen and (max-width: 575px) {
  .latest-activity-card .card-body .latest-update-box .update-meta {
    z-index: 2;
    min-width: 100%;
    text-align: left !important;
    margin-bottom: 15px;
    border-top: 1px solid #f1f1f1;
    padding-top: 15px;
  }
}

.feed-card h6 {
  margin-top: 7px;
}

.feed-card .feed-icon {
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 8px;
}

.latest-activity-card .table td {
  vertical-align: middle;
}

.order-visitor-card {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.order-visitor-card h4,
.order-visitor-card h6,
.order-visitor-card p {
  transition: all 0.3s ease-in-out;
}

.order-visitor-card:hover {
  background-color: #4680ff;
}

.order-visitor-card:hover h4,
.order-visitor-card:hover h6,
.order-visitor-card:hover p {
  color: #fff;
}

.user-card-full {
  overflow: hidden;
}

.user-card-full .user-profile-side {
  border-radius: 5px 0 0 5px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  transition: all 0.3s ease-in-out;
}

.user-card-full .social-link li a i {
  color: #373a3c;
}

.form-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.form-icon .btn.btn-icon {
  width: 35px;
  height: 35px;
}

.widget-statstic-card {
  position: relative;
  overflow: hidden;
}

.widget-statstic-card .st-icon {
  color: #fff;
  font-size: 23px;
  padding: 40px 40px 20px 20px;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  right: -30px;
  transition: all 0.3s ease-in-out;
}

.widget-statstic-card h2 {
  font-weight: 600;
  display: inline-block;
}

.widget-statstic-card span {
  border-radius: 30px;
  padding: 5px 20px;
  color: #fff;
  font-weight: 600;
}

.widget-statstic-card:hover .st-icon {
  font-size: 50px;
}

.widget-visitor-card {
  overflow: hidden;
  padding: 10px 0;
}

.widget-visitor-card i {
  color: #fff;
  font-size: 80px;
  position: absolute;
  bottom: -10px;
  opacity: 0.3;
  left: -10px;
  transform: rotate(15deg);
  transition: all 0.3s ease-in-out;
}

.widget-visitor-card:hover i {
  transform: rotate(0deg) scale(1.4);
  opacity: 0.5;
}

.app-design .btn {
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
}

.app-design .team-section img {
  width: 35px;
  border-radius: 5px;
}

.app-design .progress-box p {
  margin-bottom: 0;
}

.app-design .progress-box .progress {
  width: calc(100% - 80px);
  height: 8px;
  text-align: center;
  margin: 0 auto;
  background-color: #e5e5e5;
  border-radius: 30px;
  position: relative;
  overflow: inherit;
}

.app-design .progress-box .progress .progress-bar {
  border-radius: 30px;
}

.app-design .progress-box .progress .progress-bar label {
  position: absolute;
  top: -24px;
  right: 0;
  font-weight: 600;
  font-size: 13px;
}

.order-card {
  color: #fff;
  overflow: hidden;
}

.order-card .card-icon {
  position: absolute;
  right: -17px;
  font-size: 100px;
  top: 20px;
  opacity: 0.5;
}

.statustic-card .card-body {
  position: relative;
}

.statustic-card .card-body .progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 5px;
  overflow: visible;
}

.statustic-card .card-body .progress .progress-bar {
  position: relative;
}

.statustic-card .card-body .progress .progress-bar:before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  background: inherit;
}

.statustic-card .card-body .progress .progress-bar.bg-c-blue:before {
  -webkit-animation: blue-blink-bar 0.5s linear infinite;
  animation: blue-blink-bar 0.5s linear infinite;
}

.statustic-card .card-body .progress .progress-bar.bg-c-green:before {
  -webkit-animation: green-blink-bar 0.5s linear infinite;
  animation: green-blink-bar 0.5s linear infinite;
}

.statustic-card .card-body .progress .progress-bar.bg-c-red:before {
  -webkit-animation: red-blink-bar 0.5s linear infinite;
  animation: red-blink-bar 0.5s linear infinite;
}

.statustic-card .card-body .progress .progress-bar.bg-c-yellow:before {
  -webkit-animation: yellow-blink-bar 0.5s linear infinite;
  animation: yellow-blink-bar 0.5s linear infinite;
}

@-webkit-keyframes blue-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(70, 128, 255, 0.1);
  }

  50% {
    box-shadow: 0 0 0 6px rgba(70, 128, 255, 0.3);
  }
}

@keyframes blue-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(70, 128, 255, 0.1);
  }

  50% {
    box-shadow: 0 0 0 6px rgba(70, 128, 255, 0.3);
  }
}

@-webkit-keyframes green-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(156, 204, 101, 0.1);
  }

  50% {
    box-shadow: 0 0 0 6px rgba(156, 204, 101, 0.3);
  }
}

@keyframes green-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(156, 204, 101, 0.1);
  }

  50% {
    box-shadow: 0 0 0 6px rgba(156, 204, 101, 0.3);
  }
}

@-webkit-keyframes red-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.1);
  }

  50% {
    box-shadow: 0 0 0 6px rgba(255, 82, 82, 0.3);
  }
}

@keyframes red-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.1);
  }

  50% {
    box-shadow: 0 0 0 6px rgba(255, 82, 82, 0.3);
  }
}

@-webkit-keyframes yellow-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 186, 87, 0.1);
  }

  50% {
    box-shadow: 0 0 0 6px rgba(255, 186, 87, 0.3);
  }
}

@keyframes yellow-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 186, 87, 0.1);
  }

  50% {
    box-shadow: 0 0 0 6px rgba(255, 186, 87, 0.3);
  }
}

.statustic-progress-card .progress {
  height: 5px;
}

.quater-card .progress {
  height: 4px;
}

.client-map img {
  vertical-align: top;
  width: 90px;
}

.client-map .client-detail {
  margin-bottom: 20px;
}

.client-map .client-detail img {
  border-radius: 5px;
}

.client-map .client-profile {
  float: left;
}

.client-map .client-contain {
  display: inline-block;
  margin-left: 20px;
}

.client-map .client-contain h5 {
  display: block;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 10px;
}

.client-map .client-card-box {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.client-map .client-card-box .client-border {
  border-right: 1px solid #ccc;
}

.client-map .client-card-box .client-border-card {
  border-top: 1px solid #ccc;
}

.flat-card .row-table {
  display: table;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}

.flat-card .row-table:first-child {
  border-bottom: 1px solid #ddd;
}

.flat-card .row-table .br {
  border-right: 1px solid #ccc;
}

.flat-card .row-table i {
  font-size: 28px;
}

.flat-card .row-table span {
  text-transform: uppercase;
  font-size: 12px;
}

.flat-card .row-table h5 {
  display: block;
  margin-bottom: 0.3em;
  margin-right: 0;
}

.flat-card .row-table > [class*="col-"] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
}

.flat-card .row-table > [class*="col-"] .row {
  display: flex;
  align-items: center;
}

.flat-card[class*="widget-"] .row-table {
  display: flex;
}

.table-card .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.table-card .card-body .table > thead > tr > th {
  border-top: 0;
}

.table-card .card-body .table tr td:first-child,
.table-card .card-body .table tr th:first-child {
  padding-left: 25px;
}

.table-card .card-body .table tr td:last-child,
.table-card .card-body .table tr th:last-child {
  padding-right: 25px;
}

.table-card .card-body .table.without-header tr:first-child td {
  border-top: none;
}

.stastic-slider-full-card .carousel-item {
  transition: transform 12s linear;
}

.statstic-data-card .active.carousel-item-right,
.statstic-data-card .carousel-item-next:not(.carousel-item-left) {
  transform: translateY(100%);
}

.statstic-data-card .active.carousel-item-left,
.statstic-data-card .carousel-item-prev:not(.carousel-item-right) {
  transform: translateY(-100%);
}

.statstic-data-card .card-body {
  border-top: 2px solid transparent;
}

.statstic-data-card .card-body.level-down {
  border-color: #ff5252;
}

.statstic-data-card .card-body.level-up {
  border-color: #9ccc65;
}

.social-widget-card {
  border-top: none;
  color: #fff;
}

.social-widget-card:hover i {
  opacity: 1;
  transform: scale(1.1);
}

.social-widget-card i {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 48px;
  opacity: 0.4;
  transition: all ease-in 0.3s;
}

.ticket-card .lbl-card {
  border-radius: 50px;
  padding: 5px 15px;
  display: inline-block;
}

.widget-profile-card-1 {
  position: relative;
  margin-bottom: 50px;
}

.widget-profile-card-1 .middle-user {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  bottom: -60px;
}

.widget-profile-card-1 .middle-user img {
  width: 115px;
  border-radius: 50%;
}

.widget-profile-card-1 ~ .card-footer [class*="col-"] {
  border-right: 1px solid #fff;
}

.widget-profile-card-1 ~ .card-footer [class*="col-"]:last-child {
  border: none;
}

@media only screen and (max-width: 767px) {
  .widget-profile-card-1 ~ .card-footer [class*="col-"]:nth-child(2) {
    border: 0;
  }

  .widget-profile-card-1 ~ .card-footer [class*="col-"]:last-child {
    margin-top: 10px;
  }
}

.widget-profile-card-2 img.user-img {
  width: 115px;
}

.widget-profile-card-2 a {
  color: #fff;
  transition: all ease-in 0.3s;
}

.widget-profile-card-2 a:hover {
  opacity: 0.7;
}

.widget-profile-card-3 {
  background-size: cover;
  padding: 50px 0;
  text-align: center;
}

.widget-profile-card-3 img {
  width: 120px;
  border-radius: 50%;
}

.profile-card {
  position: relative;
  min-height: 410px;
  overflow: hidden;
}

.profile-card img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.profile-card .card-body {
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.72));
}

.profile-card .card-body h3 {
  font-weight: 600;
}

.profile-card ~ .card-footer [class*="col-"] {
  padding: 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.57);
}

.profile-card ~ .card-footer [class*="col-"] h4 {
  font-size: 18px;
  font-weight: 600;
}

.profile-card ~ .card-footer [class*="col-"]:last-child {
  border: none;
}

.profile-card ~ .card-footer [class*="col-"] span {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .profile-card ~ .card-footer [class*="col-"]:nth-child(2) {
    border: none;
  }
}

.blur-user-card {
  color: #fff;
  text-align: center;
  padding-top: 45px;
  background-size: cover;
}

.blur-user-card h3 {
  margin-top: 10px;
  font-size: 30px;
  font-weight: 600;
}

.blur-user-card p {
  font-size: 18px;
  font-weight: 300;
}

.blur-user-card .blur-footer [class*="col-"] {
  padding: 1em;
  border-right: 1px solid #fff;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blur-user-card .blur-footer [class*="col-"]:last-child {
  border-right: none;
}

@media only screen and (max-width: 767px) {
  .blur-user-card .blur-footer [class*="col-"]:nth-child(2) {
    border: 0;
  }

  .blur-user-card .blur-footer [class*="col-"]:last-child {
    margin-top: 0;
    padding-top: 0;
  }
}

.weather-bg-card {
  position: relative;
}

.weather-bg-card img {
  width: 100%;
}

.weather-bg-card .card-body {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.weather-bg-card h5 {
  color: #fff;
}

.weather-bg-card .card-body.alt {
  position: absolute;
  top: 0;
}

.weather-bg-card .card-body.alt span {
  color: #fff;
}

.weather-bg-card .weather-temp {
  font-size: 70px;
  color: #fff;
}

.weather-bg-card ~ .card-footer {
  border-top: none;
}

@media only screen and (max-width: 575px) {
  .weather-bg-card ~ .card-footer [class*="col-"] {
    margin-bottom: 10px;
  }
}

.new-cust-card img {
  width: 40px;
}

.new-cust-card h6 {
  margin-bottom: 0;
}

.new-cust-card .align-middle {
  position: relative;
}

.new-cust-card .align-middle .status {
  position: absolute;
  right: 0;
  top: 19px;
  font-size: 13px;
}

.new-cust-card .align-middle .status.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #9ccc65;
}

.latest-update-card.update-card .card-body .latest-update-box:after {
  left: 19px;
}

.testimonial-card .progress {
  height: 5px;
}

.testimonial-card .review-block .cust-img {
  width: 40px;
  height: 40px;
}

.testimonial-card .review-block > div {
  padding-top: 15px;
  padding-bottom: 9px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.testimonial-card .review-block > div:hover {
  background-color: rgba(70, 128, 255, 0.1);
}

.widget-chat-box .card-header i {
  font-size: 17px;
  color: #263238;
  position: relative;
}

.widget-chat-box .card-header .pop-up:after {
  content: "";
  position: absolute;
  background-color: #ff5252;
  height: 8px;
  width: 8px;
  border-radius: 50px;
  right: -3px;
}

.widget-chat-box .send-chat,
.widget-chat-box .receive-chat {
  position: relative;
  background: #dfe9ff;
  padding: 7px;
  border-radius: 0 10px 10px 10px;
  font-size: 13px;
  margin-bottom: 30px;
}

.widget-chat-box .send-chat:before,
.widget-chat-box .receive-chat:before {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  border: 6px solid transparent;
  border-top: 6px solid #dfe9ff;
  border-right: 6px solid #dfe9ff;
  left: -12px;
  top: 0;
}

.widget-chat-box .send-chat .time,
.widget-chat-box .receive-chat .time {
  position: absolute;
  bottom: -20px;
  left: 0;
}

.widget-chat-box .receive-chat {
  background-color: #4680ff;
  color: #fff;
  border-radius: 10px;
}

.widget-chat-box .receive-chat:before {
  display: none;
}

.widget-chat-box .receive-chat .time {
  color: #666;
}

.widget-chat-box .rc-10 {
  margin-bottom: 10px;
}

.chat-card .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 10px;
  position: relative;
}

.chat-card .msg img {
  width: 60px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;
}

.chat-card .received-chat .msg {
  background: #dfe9ff;
  border-radius: 0 5px 5px 5px;
}

.chat-card .received-chat .msg:after {
  content: "";
  position: absolute;
  left: -7px;
  top: -7px;
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-bottom-color: #dfe9ff;
}

.chat-card .send-chat,
.chat-card .widget-chat-box .receive-chat,
.widget-chat-box .chat-card .receive-chat {
  text-align: right;
}

.chat-card .send-chat .msg,
.chat-card .widget-chat-box .receive-chat .msg,
.widget-chat-box .chat-card .receive-chat .msg {
  background: #4680ff;
  color: #fff;
  border-radius: 5px 0 5px 5px;
}

.chat-card .send-chat .msg:after,
.chat-card .widget-chat-box .receive-chat .msg:after,
.widget-chat-box .chat-card .receive-chat .msg:after {
  content: "";
  position: absolute;
  right: -7px;
  top: -7px;
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-right-color: #4680ff;
}

.nav {
  position: relative;
  z-index: 1;
}

.feed-card h6 {
  margin-top: 7px;
  font-size: 14px;
}

.feed-card .feed-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 8px 9px;
}

.mrr-card {
  position: relative;
}

.mrr-card .bottom-chart {
  bottom: 0;
}

/**  =====================
      Data Tables css start
==========================  **/
.table td,
.table th {
  border-top: 1px solid #e2e5e8;
  white-space: nowrap;
  padding: 1.05rem 0.75rem;
}

.table thead th {
  border-bottom: 1px solid #e2e5e8;
  font-size: 13px;
  color: #37474f;
  background: #ecf0f5;
  text-transform: uppercase;
}

.table tbody + tbody {
  border-top: 2px solid #e2e5e8;
}

.table.table-dark thead th {
  background-color: #11171a;
}

.table.dataTable[class*="table-"] thead th {
  background: #ecf0f5;
}

/* Border versions */
.table-bordered {
  border: 1px solid #e2e5e8;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #e2e5e8;
}

/* Zebra-striping */
.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(70, 128, 255, 0.03);
}

/* Hover effect */
.table-hover tbody tr:hover {
  background-color: rgba(70, 128, 255, 0.03);
}

/* Table backgrounds */
.table .thead-dark th {
  color: #fff;
  background-color: #263238;
  border-color: #11171a;
}

.table-dark {
  color: #fff;
  background-color: #263238;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #11171a;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #222d32;
}

.table-dark.table-hover tbody tr:hover {
  background-color: #1c2429;
}

.table-dark thead th {
  color: #fff;
}

/* fixed header position */
table.dataTable.fixedHeader-floating {
  top: 0 !important;
}

@media screen and (max-width: 992px) {
  table.dataTable.fixedHeader-floating {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important;
  }
}

/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > .active > a,
.footable .pagination > .active > span {
  background-color: #4680ff;
  border-color: #4680ff;
}

.footable .pagination > .active > a:focus,
.footable .pagination > .active > a:hover,
.footable .pagination > .active > span:focus,
.footable .pagination > .active > span:hover {
  background-color: #4680ff;
  border-color: #4680ff;
}

.footable .pagination > li > a,
.footable .pagination > li > span {
  color: #222;
}

.footable-details.table,
.footable.table {
  margin-bottom: 0;
}

table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0;
}

table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0;
}

.table-columned > tbody > tr > td {
  border: 0;
  border-left: 1px solid #e2e5e8;
}

.table-columned > tbody > tr > th {
  border: 0;
}

/**====== Foo-table css end ======**/
/**======= basic table css start =======**/
.table.table-xl td,
.table.table-xl th {
  padding: 1.25rem 2rem;
}

.table.table-lg td,
.table.table-lg th {
  padding: 0.9rem 2rem;
}

.table.table-de td,
.table.table-de th {
  padding: 0.75rem 2rem;
}

.table.table-sm td,
.table.table-sm th {
  padding: 0.6rem 1rem;
}

.table.table-xs td,
.table.table-xs th {
  padding: 0.4rem 2rem;
}

/**======= basic table css end ======**/
#row-delete .selected,
#row-select .selected {
  background-color: #4680ff;
  color: #fff;
}

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background: #4680ff;
}

table.dataTable
  tbody
  > tr.selected
  table.dataTable
  tbody
  > tr
  > .selected
  td.select-checkbox:after,
table.dataTable
  tbody
  > tr.selected
  table.dataTable
  tbody
  > tr.selected.select-checkbox:after,
table.dataTable tbody > tr.selected th.select-checkbox:after,
table.dataTable
  tbody
  > tr
  > .selected
  table.dataTable
  tbody
  > tr
  > .selected
  td.select-checkbox:after,
table.dataTable
  tbody
  > tr
  > .selected
  table.dataTable
  tbody
  > tr.selected.select-checkbox:after,
table.dataTable tbody > tr > .selected th.select-checkbox:after {
  text-shadow: 1px 1px #e4f2fe, -1px -1px #e4f2fe, 1px -1px #e4f2fe,
    -1px 1px #e4f2fe;
}

table.dataTable tbody .selected td.select-checkbox:before,
table.dataTable tbody .selected th.select-checkbox:before {
  border-color: #fff;
}

table.dataTable tbody > tr > .selected td.select-checkbox:after,
table.dataTable tbody > tr > .selected th.select-checkbox:after,
table.dataTable tbody > tr.selected td.select-checkbox:after,
table.dataTable tbody > tr.selected th.select-checkbox:after {
  content: "\2714";
  margin-top: -11px;
  margin-left: -4px;
  text-align: center;
}

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: #dfe9ff;
  color: #4680ff;
  border: 2px solid #4680ff;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}

div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  color: #fff;
  border-bottom: 1px solid #4680ff;
  background-color: #4680ff;
}

div.dt-button-info > div {
  padding: 1em;
}

.dt-buttons {
  margin-bottom: 5px;
}

table.dataTable tbody td.focus,
table.dataTable tbody th.focus {
  box-shadow: inset 0 0 1px 2px #4680ff;
}

div.dtk-focus-alt table.dataTable tbody td.focus,
div.dtk-focus-alt table.dataTable tbody th.focus {
  box-shadow: inset 0 0 1px 2px #ffba57;
  background: rgba(255, 186, 87, 0.15);
  color: #ffba57;
}

/**====== Data Tables css end ======**/
/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  background: #4680ff;
}

@media (min-width: 1200px) {
  .auth-wrapper .container {
    max-width: 1140px;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper {
    display: block;
    margin: 0 auto;
  }

  .auth-wrapper > .row {
    min-height: 100vh;
  }

  .auth-wrapper > .row > .aut-bg-img {
    min-height: 100vh;
  }
}

.auth-wrapper .saprator {
  position: relative;
  margin: 8px 0;
}

.auth-wrapper .saprator span {
  background: #fff;
  position: relative;
  padding: 0 10px;
  z-index: 5;
  font-size: 20px;
}

.auth-wrapper .saprator:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e2e5e8;
  z-index: 1;
}

.auth-wrapper a,
.auth-wrapper p > a {
  color: #37474f;
  font-weight: 600;
}

.auth-wrapper .input-group {
  background: transparent;
}

.auth-wrapper .card {
  margin-bottom: 0;
  padding: 8px;
}

.auth-wrapper .card .card-body {
  padding: 20px 25px 20px 40px;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper .card {
    display: block;
  }
}

.auth-wrapper > div {
  z-index: 5;
}

.auth-wrapper .auth-content {
  position: relative;
  padding: 15px;
  z-index: 5;
}

.auth-wrapper .auth-content:not(.container) {
  width: 400px;
}

.auth-wrapper .auth-content:not(.container) .card-body {
  padding: 40px 35px;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper .auth-content {
    margin: 0 auto;
  }
}

.auth-wrapper .auth-side-img {
  padding-right: 400px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .auth-wrapper .auth-side-img {
    display: none;
  }
}

.auth-wrapper .auth-side-form {
  position: relative;
  min-height: 100%;
  background: #fff;
  display: inline-flex;
  align-items: center;
  left: auto;
  float: right;
}

.auth-wrapper .auth-side-form > * {
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 991px) {
  .auth-wrapper .auth-side-form {
    width: 100%;
    justify-content: center;
  }

  .auth-wrapper .auth-side-form .auth-content:not(.container) {
    max-width: 350px;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper.aut-bg-img .auth-side-form {
    height: 100vh;
  }
}

@media only screen and (max-width: 767px) {
  .auth-wrapper .card .card-body {
    padding: 30px 15px;
  }
}

.auth-wrapper.offline {
  background-image: none;
}

.auth-wrapper.offline h1 {
  font-size: 87px;
  font-weight: 700;
}

.auth-wrapper.offline:before {
  display: none;
}

.auth-wrapper .card-body .carousel-indicators {
  margin: 15px 0 10px;
  bottom: 0;
}

.auth-wrapper .card-body .carousel-indicators li {
  width: 50px;
  background-color: rgba(70, 128, 255, 0.4);
  border-radius: 5px;
  height: 4px;
}

.auth-wrapper .card-body .carousel-indicators li.active {
  background-color: #4680ff;
}

.auth-wrapper .img-logo-overlay {
  position: absolute;
  top: 40px;
  left: 50px;
}

/* image varient start */
.aut-bg-img {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.aut-bg-img .auth-content {
  padding: 70px 40px;
}
.position-relative {
  position: relative;
}

.notification-count {
  position: absolute;
  top: 15px; /* Adjust this to position vertically */
  right: 10px; /* Adjust this to position horizontally */
  transform: translate(50%, -50%); /* Center align it above the corner */
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.2em 0.4em; /* Adjust for size */
  font-size: 0.75em; /* Adjust for size */
}
/* image varient End */
/**====== Authentication css end ======**/
/* my styles */
.navigateheaders {
  width: 100%;
  height: 20px;
  margin-bottom: 0.9rem;
  display: grid;
  grid-template-columns: 1fr 40fr 1fr;
}
.listemail{
  display: flex;
  margin-bottom: 2px;
}
.meet{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event-title {
  white-space: nowrap;          /* Prevents the text from wrapping */
  overflow: hidden;             /* Hides the overflowing text */
  text-overflow: ellipsis;      /* Adds the ellipsis (...) when the text overflows */
  max-width: 200px;             /* Set a maximum width for the element */
  display: inline-block;        /* Makes the span behave like a block element */
  cursor: pointer;
}
.cornerbtn {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.course {
  transition: transform 0.3s ease; /* Smooth zoom effect */
}
.course .card{
  width:280px
}

.course:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}
ul {
  list-style: none;
}

.tableheader {
  display: grid;
  grid-template-columns: 25fr 10fr;
}
.tableheader2 {
  display: grid;
  grid-template-columns: 25fr 3fr;
}
.tableheader3 {
  display: grid;
  grid-template-columns: 10fr 6fr;
}
.selectandadd {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.selectstyle {
  height: 40px;
  margin: auto;
}
.mybtn {
  min-width: 100px;
  max-width: 150px;
  height: 40px;
  margin: auto;
}

.table-container {
  min-height: 400px;
  width: 100%;
  overflow-y: auto;
}
.centerflex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
.enroll {
  width: 400px;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}
.singletest {
  flex: 1;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 2px;
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 3fr 3fr 3fr;
  gap: 10px;
}
.edititems b {
  font-size: 0.9rem;
}
.edititems {
  cursor: pointer;
  display: grid;
  grid-template-columns: 5fr 8fr 1fr;
}
.atbtndiv {
  display: grid;
  grid-template-columns: 2fr 40fr 2fr;
  gap: 30px;
}
.calenderheadergrp {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 10px;
}
.btngrp {
  display: flex;
  justify-content: end;
  margin-right: 30px;
  margin-top: 10px;
  padding-bottom: 10px;
  padding: 10px;
}
.mainform {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
}
.profile-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-group {
  width: 300px; /* Adjust the width and height as needed */
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px; /* Adjust the margin as needed */
}
#google-signin-button {
  width: 30px;
  height: 30px;
}
#google-signin-button img,
.image-group img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
}
.hidebtn {
  border: none;
  outline: none;
  color: #264bc7;
  background-color: transparent;
}

.Activeuser {
  background-color: #ecfdf3;
  color: #037847;
  font-size: small;
  width: 90px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}

.InActiveuser {
  background-color: #edeff1;
  color: #6c778b;
  font-size: small;
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}
.doclinkedit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.doclinkedit,
.doclink {
  color: #2f3ced;
  cursor: pointer;
}
.doclinkedit i,
.doclink i {
  text-indent: 10px;
}
.listback {
  width: 100%;
  background-color: #f2e1f5;
  border-radius: 10px;
  padding: 10px;
  height: auto;
  min-height: 450px;
}
.listbackinner {
  width: 100%;
  margin-top: 10px;
  background-color: #cbcbcb;
  padding: 10px;
  border-radius: 5px;
  font-size: larger;
  display: grid;
  grid-template-columns: 1fr 20fr 1fr 1fr;
}

.twodiv {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 6fr;
}
.innerdivider {
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 15px;
}
.headingbtn {
  display: grid;
  grid-template-columns: 10fr 1fr;
}

.videoinputs {
  padding: 20px;
  height: min-content;
  display: grid;
  grid-template-rows: 1fr 1fr 4fr;
}
.dropzone {
  max-height: 300px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 10px;
  overflow: auto;
  text-align: center;
  align-items: center;
}
.dropzone p {
  margin-bottom: 5px;
}
.file-upload-btn {
  display: inline-block;
  background-color: var(--primary);
  color: white;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.file-upload {
  position: absolute;
  left: -9999px;
}

/* Style to hide the file input filename */
.file-upload::-webkit-file-upload-button {
  visibility: hidden;
}

.file-upload::before {
  content: "Choose File";
  display: inline-block;
  color: white;
  background-color: var(--primary);
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.file-upload:valid + .file-upload-btn::before {
  content: "Change File";
}
.main {
  display: grid;
  grid-template-columns: 4fr 3fr;
  gap: 30px;
  height: auto;
  width: 100%;
  padding: 10px;
  position: relative;
}

.description{
  width: 650px;
}
.list {
  background-color: #4680fe;
  border-radius: 7px;
  height: 100%;
  max-height: 650px;
  display: flex;
  flex-direction: column;
}

.content {
  height: 100%;
  align-items: center;
  background-color: #e9e9e9;
  border-top: 3px solid #e9e9e9;
  overflow-y: auto; /* Use auto to add scrollbar only when necessary */
}
.vdobox{
  height: 400px;
  width: 650px;
  border: 3px solid #e9e9e9;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.notes {
  height: 80px;
  background-color: white;
  color: black;
  text-align: start;
  margin: 5px 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template-columns: 2fr 8fr;
  gap: 5px;
  font-weight: bold;
  cursor: pointer;
}
.lessonheading {
  color: white;
  margin: 15px 10px;
  font-size: 1.5rem;
}
.child {
  height: 98%;
  border-radius: 5px;
  overflow: hidden;
}
.datetimegrp {
  width: 100%;
  display: flex;
  gap: 10px;
}

.zoomset {
  width: 50%;
  display: grid;
  grid-template-columns: 5fr 1fr 2fr 1fr 2fr;
}
.boxtype {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  border: 1px solid #ced4da;
  background-color: #e9ecef;
}
.check{
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.radio{
  width: 20px !important;
  height: 20px !important;
  margin: 0 5px;
}
.zoomopt input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.zoomset input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.zoomopt {
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 20fr;
}

.zoomset p {
  align-items: left;
}
.user-list {
  margin-top: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px;
  height: auto;
  max-height: 100px;
  overflow-y: scroll;
}
.usersingle {
  cursor: pointer;
  margin: 5px;
}
.usersingle :hover {
  background-color: #eae7e7;
}

.usersingle label {
  font-size: 0.9rem;
}
.inputlike {
  border: none;
  padding: 5px;
  border: 1px solid #ced4da;
}
.inputlikeeffect {
  border: none;
  border-bottom: 1px solid #ced4da;
  background-image: linear-gradient(to right, #007bff, #007bff);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 0% 1px; /* Only show as a line at the bottom */
  transition: background-size 0.3s ease-out;
}

.inputlikeeffect:focus-within {
  background-size: 100% 3px; /* Expand the line across the entire width */
  outline: none;
}

.inputeffect {
  border: none;
  border-bottom: 1px solid #ced4da;
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: background 0s ease-out;
}
.selectedemail {
  padding: 0px 10px;
  width: fit-content;
  background-color: #eae7e7;
  border-radius: 20px;
}
.part2 {
  width: 100%;
  display: grid;
  position: absolute;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  background-color: #b3b6e1;
}
.twosplit {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.phone-input-container {
  border-bottom: 1px solid #ced4da;
  display: flex;
  align-items: center;
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: background 0s ease-out;
}
.Readonlyinp input,
.Readonlyinp {
  background-color: #e9ecef;
  opacity: 1;
  color: #495057 !important;
}
.PhoneInput {
  display: flex;
  align-items: center;
  width: 100%;
}

#phone {
  opacity: 1;
  padding: 0;
  margin: 0;
  min-height: 20px;
  height: 100%;
  border: none;
  outline: none;
  flex: 1;
}
.inputpsw {
  display: grid;
  grid-template-columns: 20fr 1fr;
  width: 100%;
}
.inputpsw input {
  border: none;
  outline: none;
}
.formgroup {
  display: grid;
}
.assignlist {
  background-color: #f2e1f5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.choice input[type="radio"],
.checkboxes input[type="checkbox"] {
  width: 20px;
  height: 20px;
  /* accent-color: black; */
}
.checkboxes label {
  /* font-size: larger; */
  padding-left: 10px;
}

.choice {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 20fr;
}
.div3 {
  padding: 10px;
  display: grid;
  grid-template-rows: 1fr 8fr 1fr;
}
.instruction {
  padding-left: 30px;
  text-align: left;
  height: 430px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.notfound {
  margin-left: 350px;
  border-radius: 10px;
  margin: auto;
  background-color: white;
  font-weight: bolder;
  text-align: center;
  width: 50%;
  padding: 30px;
}
.atgrid {
  padding: 10px;
  height: 65vh;
  display: grid;
  grid-template-rows: 1fr 5fr;
}

.instruction li {
  margin-left: 100px;
}
.listgroup {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  padding-right: 5px;
}
.lielement {
  margin-bottom: 20px;
}

.lielement input[type="radio"] {
  margin-right: 5px; /* Add margin-right to create space between the radio button and label */
}

.lielement label {
  vertical-align: middle; /* Align the label vertically with the radio button */
}
.atdiv {
  width: 100%;
  height: 500px;
  padding: 10px;
  display: grid;
  grid-template-rows: 5fr 1fr;
}
.img-profile {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}
.login-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: auto;
  width: auto;
}
.image-section {
  height: 97vh;
}
.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.card-login {
  width: 35vw;
}
.card-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.alignright {
  text-align: right;
}
.createbtn {
  width: 100%;
  display: flex;
  justify-content: end;
}
#printbtn {
  width: 200px;
}
.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  width: 100%;
  height: 100%;
  border: 10px solid #b2b5e1;
  display: grid;
  grid-template-rows: max-content;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
  background-repeat: no-repeat;
  font-size: 1.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}
.centerimg {
  display: flex;
  justify-content: center;
}
.inner p {
  word-wrap: break-word;
  font-size: smaller;
  margin-bottom: 0px;
}
.certificate {
  width: 700px;
  height: 400px;
}
.certibtn {
  width: 700px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 30px;
}
.slider_div {
  padding: 5px;
  width: 100%;
  width: 1000px;
  max-height: 600px;
  overflow: auto;
}
.slider_div img {
  margin: auto;
}
.outermini {
  width: 150px;
  height: 600px;
  align-content: center;
  overflow: auto;
  padding: 5px;
}
.activemini {
  border: 4px solid black;
}
.pageshow {
  background-color: rgba(190, 113, 141, 0.37);
  width: auto;
  margin: auto;
  padding: 5px 45px;
}
.currentslide {
  outline: none;
  border: none;
  width: auto;
  max-width: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px;
}
.zoombtn {
  cursor: pointer;
  padding: 6px;
}
.zoombtn:hover {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}
.pageshowchild {
  padding-right: 20px;
  border-right: 1px solid white;
}
.headdocu {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 5px;
  color: white;
  border-bottom: 2px solid white;
}
.wrapppt {
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  background-color: #6b6b6b;
}
.miniandoriginal {
  width: 100%;
  display: flex;
}

.miniimg p {
  text-align: center;
  color: white;
}
.heightmini {
  height: 125px;
}
.activemini img,
.miniimg img {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  height: 100%;
  width: 100px;
  object-fit: cover;
}
.assignlist {
  background-color: #f2e1f5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.hidebtn2 {
  border: none;
  outline: none;
  color: white;
  background-color: transparent;
}
.padnone {
  padding: 6px 0px !important;
}

.toggle-list {
  list-style-type: none;
  padding: 10px;
  margin: 0;
  height: auto;
  display: none;
  transition: all 0.9s ease-in-out;
}
.toggle-list label {
  width: 80%;
  float: left;
  padding-left: 10px;
}
.view-course {
  display: flex;
  flex-direction: column;
}
.view-course.active .toggle-list {
  display: block;
}
/* Hide the original checkbox input */
.checkbox-label input[type="checkbox"] {
  display: none; /* Hides the actual checkbox */
}

/* Style the custom checkbox (span) */
.checkbox-label .checkbox-custom {
  width: 18px;
  height: 18px;
  border: 2px solid #aaa;
  display: inline-block;
  border-radius: 4px;
  margin-right: 8px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
}

/* Change the appearance when the checkbox is checked */
.checkbox-label input[type="checkbox"]:checked + .checkbox-custom {
  background-color: #007bff;
  border-color: #007bff;
}

/* Add a checkmark inside the custom checkbox */
.checkbox-label input[type="checkbox"]:checked + .checkbox-custom::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Make sure the label looks clickable */
.checkbox-label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.radial-bar {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 30px;
}
/* In your CSS file (e.g., App.css) */
.chart-container {
  transform-origin: bottom; /* Set the transform origin to the bottom */
  animation: growAnimation 2s ease-out forwards; /* Apply the animation */
}

@keyframes growAnimation {
  0% {
    transform: scaleY(
      0
    ); /* Start with scaleY(0), effectively making it invisible */
  }
  100% {
    transform: scaleY(1); /* End at scaleY(1), showing the full chart */
  }
}
.listviewDashboard{
  height: 420px;
  overflow-y: auto;
}

.listviewDashboard::-webkit-scrollbar {
  width: 5px;
}


.listviewDashboard::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 5px;
}

.listviewDashboard::-webkit-scrollbar-thumb {
  background-color: #999; /* Color of the scrollbar thumb */
  border-radius: 4px;
}


.imgcontainer {
  background: 
      linear-gradient(45deg, #e0e0e0 25%, transparent 25%, transparent 75%, #e0e0e0 75%, #e0e0e0),
      linear-gradient(45deg, #e0e0e0 25%, transparent 25%, transparent 75%, #e0e0e0 75%, #e0e0e0);
  background-position: 0 0, 5px 5px;
  background-size: 10px 10px; /* Adjust size of squares here */
}

.imgcontainer img {
  width: 100%;
  height: 100%;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
  font-size: larger;
  font-weight: 700;
}

.marquee-container:hover .marquee-content {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.payment-image {
  max-width: 100%; /* Ensures the image fits within the container */
  height: auto;
  cursor: pointer; /* Maintains the aspect ratio */
}
.payment-image:hover {
  border-radius: 10px;
  border: 1px solid #4680ff;
}
.twosplits {
  min-height: 70vh;
  display: grid;
  grid-template-columns: 4fr 3fr;
  gap: 10px;
}
.selectedpay {
  border-radius: 10px;
  border: 3px solid #4680ff !important;
}
.borderCurved {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3); /* Optional: Add border with opacity */
  border-radius: 10px; /* Optional: Adds rounded corners */
}

.horizontal-line {
  width: 98%;
  height: 1px;
  background-color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Change the color and opacity as needed */
  margin: auto; /* Adjust the spacing before and after the line */
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-xmark {
  cursor: pointer;
  margin-bottom: 10px;
}
.payrow {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.payrow .payimgdiv {
  padding: 10px;
}
@media screen and (max-width: 945px) {
  .twosplits {
    grid-template-columns: 1fr;
  }
}
.lesson-title {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  display: inline-block;
  max-width: 100%; /* Allow the title to take full available width */
}
.courseName{
  font-weight: 1000;
  color:var(--primary) ;
  overflow-wrap: normal;
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  display: inline-block;
  max-width: 100%; /* or any specific width */
  overflow: hidden; /* Hide overflowed content */
}
.courseDescription{
  font-size: .7rem;
  text-overflow: ellipsis; 
  max-width: 100%;
  height: 50px;
  margin-bottom: .7rem;
}
.amt {
  display: flex;                 /* Align the icon and text in a row */
  align-items: center;           /* Vertically center the content */
  max-width: 90%;                /* Limit the width to 90% of the parent */
  overflow: hidden;              /* Hide overflowed content */
  white-space: nowrap;           /* Prevent text from wrapping */
}

.amt span {
  text-overflow: ellipsis;       /* Show ellipsis for overflowed text */
  overflow: hidden;              /* Hide the overflowing content */
  white-space: nowrap;           /* Prevent the span text from wrapping */
  flex-shrink: 1;                /* Allow the span to shrink and create ellipsis */
}

.amountGrid{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 480px) {
  .lesson-title {
    max-width: 180px; /* Adjust for smaller screens */
  }
  .listbackinner{
    grid-template-columns: 1fr 10fr 1fr 1fr ;
    gap:5px;
  }
  .twosplits {
    grid-template-columns: 1fr;
  }
  .pageshow {
    padding: 5px;
  }
  .slider_div img {
    width: 100%;
    height: 90vw;
    object-fit: contain;
  }

  .certificate {
    width: 300px;
    height: 300px;
  }
  .certibtn {
    width: 300px;
    gap: 5px;
    grid-template-columns: 1fr;
  }
  .inner {
    margin-top: 10px;
  }
  .inner h3 {
    font-size: 1.2rem;
  }
  .inner h4 {
    font-size: 1.1rem;
  }
  .inner p {
    font-size: 0.9rem;
  }

  .card-login {
    width: 90vw;
  }
  .login-container {
    height: auto;
    grid-template-columns: 1fr;
  }
  .image-section {
    display: none;
  }
  .atdiv {
    padding: 10px;
  }
  .choice {
    margin-top: 10px;
    gap: 5px;
  }
  .assignlist {
    grid-template-columns: 1fr 1fr;
  }
  .twosplit {
    margin-top: 10px;
    grid-template-columns: 1fr;
  }
  .part2 {
    grid-template-columns: 1fr;
  }
  .main {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    padding: 10px;
  }
  .innerdivider {
    grid-template-columns: 1fr;
  }
  .image-group {
    width: 200px;
    height: 200px;
  }
  .profile-picture {
    height: auto;
  }
  .mainform {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    max-height: fit-content;
  }
  .singletest {
    grid-template-columns: 3fr 3fr;
    gap: 5px;
  }
  .tableheader2,
  .tableheader3,
  .tableheader {
    grid-template-columns: 1fr;
  }
  .selectstyle {
    justify-self: flex-start;
    width: 100px;
    height: 35px;
    margin: 5px;
  }
  .mybtn {
    height: 35px;
    margin-top: 4px;
    margin-bottom: 2px;
  }
  .enroll {
    width: 300px;
  }
}
